@media screen and (min-width:768px) and (max-width:1023px) {
  * {
    margin: 0;
    padding: 0;
    font-family: var(--poppins-light);
    font-size: 1.95vw;
    caret-color: var(--color-primary);
  }

  .managepagesBg {
    min-height: calc(100vh - 32vw) !important;
  }

  .managelocationBg {
    min-height: calc(100vh - 27vw) !important;
  }

  .createpagesBg {
    min-height: calc(100vh - 25vw) !important;
    padding-bottom: 2vw !important;
  }

  .manageContentBg {
    min-height: calc(100vh - 17vw) !important;
  }

  .multiSelectDropdown__dropdown-indicator {
    background-size: 2.5vw !important;
    padding: 2.25vw 3vw !important;
  }

  .form-select {
    background-size: 2.5vw !important;
  }

  .field-label,
  .modalWithoutTabs .modal-title,
  .modalWithoutTabs .bodyTitle,
  .invoicesEnquiry,
  .myAccountCardTitle,
  .tab-title,
  .pcTitle,
  .pageSubTitle,
  .linkText {
    font-size: 2.25vw !important;
  }

  ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-year-field, .multiSelectDropdown__placeholder,
  .planningEmployeeDetails *,
  .adminCardDetails *,
  .autoCompleteWithSearchWrapper ::placeholder, .autoCompleteWithSearchWrapper ::-webkit-input-placeholder,
  .p-autocomplete-item,
  .sendText *,
  .helpText,
  .inputFieldPrefix *,
  .createpagesBgInvite .employeeRegisterErrorText, .createpagesBgInvite .employeeRegisterErrorText>*,
  .privacyText,
  .custom-multi-value span,
  .custom-option span,
  .reportIssue .fileNameUploaded .file-upload {
    font-size: 1.95vw !important;
  }

  input[type='text'].inputFieldColor, input[type='password'], input[type='email'], input[type='tel'], input[type='search'] {
    min-height: 5vw !important;
  }

  .multiSelectDropdown__control, .signInBtn,
  .resetBtn,
  .autoCompleteWithSearchWrapper .p-autocomplete .autoCompleteInput {
    min-height: 5vw !important;
  }

  .resetBtn svg,
  .cardEditIcon svg {
    width: 3vw !important;
    height: 3vw !important;
  }

  .resetBtn {
    min-width: 6vw !important;
  }

  .modalSaveBtn,
  .form-control::placeholder,
  .form-control::-webkit-input-placeholder,
  .showPasswordText, .multiSelectDropdown__option,
  .multiSelectDropdown__single-value,
  input,
  .searchBtn, .hourWrapper select, .minuteWrapper select,
  .colonWrapper,
  textarea,
  .mobileFont,
  #lang-select,
  .TableHeader th, .cardTitle,
  .access-denied-content,
  .page-not-found-back-link,
  .resetBtn, .table td::before,
  .table td, .noRecordsStyle,
  .fileUploadWrapper .upload-button,
  .certificateUpload .btn,
  .text-danger,
  .ck.ck-editor__main p, .ck.ck-editor__main p *,
  .location-manage-tabs button,
  .locationManagerCards * {
    font-size: 1.95vw !important;
  }

  .ck.ck-editor__main p, .ck.ck-editor__main p * {
    line-height: 3vw !important;
  }

  .colonWrapper {
    width: 5%;
    text-align: center;
  }

  .hourWrapper, .minuteWrapper {
    width: 15%;
    text-align: center;
  }

  .value-color,
  .valueOption {
    font-size: 3vw !important;
  }

  .hourWrapper select, .minuteWrapper select {
    width: 100%;
    text-align: center;
    min-height: 6vw !important;
  }

  .searchBtn {
    min-width: 15vw !important;
    min-height: 5vw;
  }

  .calender-icon svg {
    width: 3vw;
    height: 3vw;
  }

  .calender-icon {
    top: 0.45vw;
  }

  .vatVerifyBtn {
    width: 100%;
    height: 5vw;
  }

  .pagebackIcon {
    width: 5vw !important;
    height: 5vw !important;
  }

  .custom-multi-value img,
  .custom-option img {
    width: 4vw !important;
    height: 4vw !important;
    object-fit: cover;
  }

  .custom-option {
    height: 5vw !important;
  }

  .pagebackIcon svg {
    width: 3vw !important;
    height: 3vw !important;
  }

  .search-bar {
    top: 12.5vw !important;
  }

  .tab-subtitle {
    font-size: 2.5vw !important;
  }


  /* Login page */
  .loginLogo {
    width: 30vw !important;
    height: 10vw !important;
  }

  input[type='checkbox'] {
    width: 2.5vw !important;
    height: 2.5vw !important;
    margin-right: 1.5vw !important;
  }

  .forgotLinkWrapper {
    padding-bottom: 6vw !important;
    padding-top: 2.5vw !important;
  }

  .loginInText {
    padding-top: 4vw !important;
    padding-bottom: 4vw !important;
  }

  .signInBtn {
    margin-top: 6vw !important;
  }

  .loginWrapper div.loginCardPadding,
  .loginWrapper div.loginCardPaddingSecond {
    padding: 3vw 2.5vw 4vw !important;
  }

  .inputFieldColor {
    padding: 0.85vw !important;
  }


  /* Header */
  .profile-dropdown-links.dropdown-menu.show {
    width: 70vw !important;
  }

  .profile-dropdown-links .dropdown-item {
    margin-bottom: 2vw !important;
    min-height: 5vw !important;
  }

  .headerContainer {
    height: 12.5vw !important;
  }

  .headerContainer .imageBorder {
    width: 7.6vw !important;
    height: 7.5vw !important;
    background-size: 7.5vw !important;
  }

  .headerContainer .imageBorder img {
    width: 95% !important;
    height: 95% !important;
  }

  .notificationIcon {
    width: 3vw !important;
    height: 3vw !important;
  }

  .headerLogo svg {
    height: 5vw !important;
    width: 30vw !important;
  }

  .tab-subtitle {
    font-size: 2.5vw !important;
  }

  .iconWrapperMobile svg,
  .viewCompaniesBg svg,
  .upload-button svg {
    width: 4vw !important;
    height: 4vw !important;
  }

  .viewCompaniesBg {
    padding: 1vw !important;
  }

  .profileDropdownMenuIcon {
    width: 6vw !important;
    height: 6vw !important;
    padding: 1.25vw !important;
  }

  /* Pagination */
  .pagination-controls svg,
  .pagination svg {
    width: 3vw !important;
    height: 3vw !important;
  }

  .pagination .btn.active, .pagination .previous-btn, .pagination .next-btn {
    width: 5.5vw !important;
    height: 5.5vw !important;
  }

  /* Access denied */
  .accessDeniedWrapper {
    height: calc(100vh - 14vw);
  }

  .access-denied-code {
    font-size: 14vw;
  }

  .access-denied-title {
    font-size: 7vw;
  }

  #lang-select {
    min-width: 11vw !important;
  }

  .dropdown-toggle {
    font-size: 2vw !important;
    border: 0.052vw solid !important;
    color: var(--color-primary) !important;
    margin-top: 2vw !important;
    margin-bottom: 1vw !important;
  }

  .grid-md-3 {
    grid-template-columns: repeat(2, 1fr);
    gap: 1vw;
  }

  .grid-med-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .locationCard img,
  .loadingIcon,
  .addCompanyImageDashboard img {
    width: 9vw !important;
    height: 9vw !important;
  }

  .modal.show .modal-dialog {
    min-width: 60% !important;
    max-width: 60% !important;
  }

  .modalWithoutTabs .btn-close {
    position: relative;
    right: -6vw;
    top: 0px;
  }

  .modalWithoutTabs.modal.show .modal-dialog {
    min-width: 85% !important;
    max-width: 85% !important;
  }

  .profile-dropdown-links .imageBorder img {
    padding: 0.3vw !important;
    left: 0.25vw !important;
  }

  .form-control:disabled {
    background-size: 2vw !important;
    background-position: right 1vw center;
  }

  .ck-toolbar__items * {
    font-size: 2vw !important;
  }

  .csvReader {
    min-height: 10vw !important;
    max-height: 10vw !important;
  }

  .pagination .page-num {
    font-size: 3vw !important;
  }

  .ellipsis {
    font-size: 4.5vw !important;
    line-height: 3vw !important;
  }

  .multiSelectDropdown__multi-value__remove svg,
  .employeeDetailsCardPlanning .cardAdministratorEditIcon svg {
    width: 2vw !important;
    height: 2vw !important;
  }

  .applicationName .form-select {
    min-height: 6vw !important;
    font-size: 1.95vw !important;
  }

  .functionCategoryField {
    min-height: 5vw !important;
    font-size: 1.95vw !important;
  }

  .functionCategoryField options {
    font-size: 1.95vw !important;
  }

  .pcAccordion .accordion-button.collapsed *,
  .pcAccordion .accordion-button *,
  .pcAccordion table td * {
    font-size: 1.95vw !important;
  }

  .pcAccordion .accordion-button::after {
    width: 3vw !important;
    height: 3vw !important;
    background-size: 3vw !important;
  }

  .fieldSuffix {
    top: 3.55vw;
    width: 9vw;
    height: 4.5vw;
    font-size: 1.95vw;
    right: 0.25vw !important;
  }

  .fieldPrefix {
    top: 3.5vw;
    left: 0.27vw;
    width: 4vw;
    height: 4.7vw;
    border-radius: 0.5vw !important;
  }

  input[type='time'] {
    min-height: 5.05vw !important;
  }

  /* .modalWithoutTabs .modal-content {
    margin-top: auto !important;
  } */

  .addCompanyModal .modal-body {
    max-height: calc(100vh - 23vw) !important;
  }

  .ck-editor__editable_inline, .custom-ckeditor {
    min-height: 15vw;
  }

  .createpagesBgInviteHeight .seachBtnWrapper,
  .marginTopFourPoint5 {
    margin-top: 4.5vw !important;
  }

  .btnWidthPwa {
    width: 25%;
  }

  .locationManagerProfile {
    width: 7vw !important;
    height: 7vw !important;
  }

  .cloneBtnMargin {
    margin-top: 3.5vw !important;
  }

  .multiSelectWrapper .custom-multi-value .close-button {
    width: 1.5vw !important;
    height: 1.5vw !important;
    background-size: 1.5vw !important;
  }

  .addAdministratorSearch .multiSelectDropdown__menu-list {
    max-height: 30vw !important;
  }

  .uploadedFile>span {
    max-width: 90%;
  }
}