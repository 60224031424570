input {
  border: 0.052vw solid var(--border-color) !important;
  font-size: var(--font-size-15) !important;
}

input:not([type='radio']):not([type='checkbox']):focus,
.multiSelectDropdown__control:focus,
.multiSelectDropdown__control:hover,
.multiSelectDropdown__control--is-focused,
.multiSelectDropdown__control--menu-is-open {
  border: 0.052vw solid var(--color-primary) !important;
  box-shadow: rgba(133, 113, 185, 0.3) 0px 0.052vw 0.104vw 0px,
    rgba(133, 113, 185, 0.25) 0px 0.052vw 0.156vw 0.052vw !important;
}

input:not([type='radio']):not([type='checkbox']).error-border,
input:not([type='radio']):not([type='checkbox']).error-border:focus {
  border: 0.104vw solid var(--color-danger) !important;
}

input[type='checkbox'] {
  width: 1.1vw !important;
  height: 1.1vw !important;
  border-radius: 0px !important;
  margin-right: 0.5vw !important;
}

input[type='checkbox']:checked {
  border: 0.052vw solid var(--color-primary) !important;
  background-color: var(--color-primary);
  accent-color: var(--color-primary) !important;
}

.form-control::placeholder,
.form-control::-webkit-input-placeholder,
.showPasswordText {
  color: var(--color-primary) !important;
  font-size: var(--font-size-15);
}

.form-control::-moz-placeholder {
  color: var(--color-primary) !important;
  font-size: var(--font-size-15);
}

.form-control:-ms-input-placeholder {
  color: var(--color-primary) !important;
  font-size: var(--font-size-15);
}

.form-control::-ms-input-placeholder {
  color: var(--color-primary) !important;
  font-size: var(--font-size-15);
}

input[type='text'].inputFieldColor,
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='search'] {
  min-height: 2.03vw !important;
}

input[type='time'] {
  min-height: 2.05vw !important;
}

input[type='date'] {
  min-height: 2.03vw !important;
}

/*
input:not([type='radio']):not([type='checkbox']):not(.multiSelectDropdown__input):not(.p-autocomplete-input) {
  min-height: 2.083vw !important;
} */

.multiSelectDropdown__control {
  height: auto !important;
  max-height: 120vw !important;
  min-height: 2.031vw !important;
}

/* Planning Search */
.autoCompleteWithSearchWrapper .p-autocomplete .autoCompleteInput {
  min-height: 1vw;
  padding: 0.1vw 0.5vw !important;
  font-family: var(--poppins-medium);
  background-color: var(--body-background);
  border: 0.052vw solid var(--body-background) !important;
  border-radius: 2.083vw;
}

.autoCompleteWithSearchWrapper ::placeholder,
.autoCompleteWithSearchWrapper ::-webkit-input-placeholder {
  font-size: var(--font-size-11) !important;
  color: var(--color-primary);
}

/* Time picker */
.hourWrapper select,
.minuteWrapper select {
  background-color: var(--color-white);
  border: 0.052vw solid var(--color-white);
  border-radius: 0vw !important;
  padding-right: 0.1vw !important;
  padding-left: 0.1vw !important;
  font-size: var(--font-size-12);
  min-width: 2.7vw !important;
  text-align: center;
}

.colonWrapper {
  font-size: var(--font-size-12);
  margin-left: 0.2vw !important;
  margin-right: 0.2vw !important;
}

/* For modern browsers */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
}

.hourWrapper select:focus-visible,
.minuteWrapper select:focus-visible {
  outline: 0 !important;
}

.hourWrapper select:focus,
.minuteWrapper select:focus {
  border: 0.052vw solid var(--color-primary) !important;
}

/* Multiselect */

.multiSelectDropdown__input-container,
.multiSelectDropdown__indicator,
.multiSelectDropdown__value-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
}

.multiSelectDropdown__value-container {
  padding-left: 0.5vw !important;
}

.multiSelectWrapper input.multiSelectDropdown__input,
.multiSelectWrapper input.multiSelectDropdown__input:focus {
  border: 0 !important;
  box-shadow: unset !important;
}

.multiSelectDropdown__control {
  background-color: var(--body-background) !important;
  border: 0.052vw solid transparent !important;
}

.multiSelectDropdown__control:focus,
.multiSelectDropdown__control:hover {
  background-color: var(--color-white) !important;
  cursor: pointer;
}

::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-year-field,
.multiSelectDropdown__placeholder {
  color: var(--dark-grey) !important;
  font-size: var(--font-size-14);
}

.multiSelectDropdown__control {
  border-radius: 0.5vw !important;
}

.multiSelectDropdown__dropdown-indicator {
  background-image: url('../images/selectDropdown.svg') !important;
  border: 0 !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1vw;
  cursor: pointer;
  padding: 0.5vw 1vw !important;
}

.multiSelectDropdown__clear-indicator {
  background-image: url('../images/Close.svg') !important;
  border: 0 !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1vw;
  cursor: pointer;
}

.multiSelectDropdown__option,
.multiSelectDropdown__single-value {
  font-size: var(--font-size-15) !important;
  padding-right: 2vw;
}

.form-select {
  --bs-form-select-bg-img: none !important;
  font-size: var(--font-size-14) !important;
  background-image: url('../images/selectDropdown.svg') !important;
  border: 0 !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1vw !important;
  cursor: pointer;
}

.form-select option {
  font-size: var(--font-size-15) !important;
}

.multiSelectDropdown__indicator svg {
  visibility: hidden;
  width: 0vw !important;
  height: 0vw !important;
}

.uploadedFile {
  color: var(--color-primary);
  font-family: var(--poppins-regular);
  text-decoration: none;
}

.uploadedFile>span {
  font-size: 0.8vw !important;
  font-family: var(--poppins-semibold);
  max-width: 15vw;
  display: block;
}

.uploadedFile:hover {
  text-decoration: underline !important;
  text-decoration-thickness: 0.8vw;
  text-underline-offset: 0.2vw;
  color: var(--secondary-color);
}


/* Access denied page design */

.accessDeniedWrapper {
  height: calc(100vh - 8vw);
  color: var(--color-primary);
}

.access-denied-code {
  font-size: var(--font-size-100);
  font-family: var(--poppins-semibold);
}

.access-denied-title {
  font-family: var(--poppins-medium);
  font-size: var(--font-size-38);
}

.access-denied-content {
  font-size: var(--font-size-24);
}

.page-not-found-back-link {
  color: var(--color-primary);
  font-family: var(--poppins-medium);
  text-decoration: underline;
  text-underline-offset: 0.3vw;
  text-decoration-thickness: 0.1vw;
  transition: transform 0.3s ease;
}

.page-not-found-back-link:hover {
  transform: scale(1.2);
}

/* Table design */

.TableHeader th {
  background-color: var(--body-background) !important;
  font-family: var(--poppins-medium);
  font-weight: normal !important;
  color: var(--color-primary) !important;
  font-size: var(--font-size-14);
}

.customTableProperties th:first-child,
.customTableProperties td:first-child {
  padding-left: 1.5vw;
}

.customTableProperties td {
  font-size: var(--font-size-16);
  vertical-align: middle;
  word-break: break-all;
}

.noRecordsData {
  color: var(--color-danger) !important;
  text-align: center;
  font-size: var(--font-size-16);
  font-family: var(--poppins-regular);
  font-weight: normal;
  padding-top: 1vw !important;
  padding-bottom: 1vw !important;
}

.backBtn {
  color: var(--dark-grey);
  font-size: var(--font-size-17);
  font-family: var(--poppins-medium);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.backBtn:hover {
  text-decoration: underline;
  text-underline-offset: 0.2vw;
  text-decoration-thickness: 0.1vw;
  color: var(--color-primary);
}

/* Ck editor */
.ck-editor {
  box-shadow: rgba(133, 113, 185, 0.3) 0px 0.052vw 0.104vw 0px,
    rgba(133, 113, 185, 0.25) 0px 0.052vw 0.156vw 0.052vw !important;
  border-radius: 0.6vw;
}

.ck.ck-editor__main>.ck-editor__editable {
  border-bottom-left-radius: 0.6vw !important;
  border-bottom-right-radius: 0.6vw !important;
}

.ck-toolbar {
  border-top-left-radius: 0.6vw !important;
  border-top-right-radius: 0.6vw !important;
}

.ck.ck-editor__main p,
.ck.ck-editor__main p * {
  font-size: var(--font-size-16) !important;
  margin-bottom: 0.7vw !important;
  line-height: 1vw;
}

.ck-editor__editable_inline,
.custom-ckeditor {
  min-height: 8vw;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  box-shadow: none !important;
  border: 0.02vw solid var(--color-primary) !important;
}

.pagebackIcon svg {
  fill: var(--color-primary);
}

.pagebackIcon:hover {
  background-color: var(--color-primary);
}

.pagebackIcon:hover svg {
  fill: var(--color-white);
}

.pagebackIcon {
  background-color: var(--body-background);
  width: 2vw !important;
  height: 2vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease;
  position: relative;
  top: -0.5vw;
}

.ck-toolbar__items * {
  font-size: var(--font-size-12) !important;
}

.searchBtn {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: 0.052vw solid var(--color-primary);
  font-size: var(--font-size-15);
  font-family: var(--poppins-medium);
  min-width: 6vw;
  min-height: 2.03vw;
  border-radius: 0.5vw;
  transition: all 0.5s ease;
}

.searchBtn:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
}

.resetBtn {
  background-color: var(--color-white);
  color: var(--secondary-color);
  border: 0.052vw solid var(--secondary-color);
  font-size: var(--font-size-15);
  font-family: var(--poppins-medium);
  min-width: 3vw;
  min-height: 2.03vw;
  border-radius: 0.5vw;
  transition: all 0.5s ease;
}

.resetBtn:hover {
  background-color: var(--secondary-color);
  color: var(--color-white);
}

.seachBtnWrapper {
  margin-top: 1.9vw !important;
}

.spinner-wrapper * {
  color: var(--color-primary);
  font-size: var(--font-size-16);
  font-family: var(--poppins-medium);
}

.text-danger {
  font-size: var(--font-size-15) !important;
}

.csvReader {
  min-height: 5vw !important;
  max-height: 5vw !important;
  font-size: var(--font-size-16);
}

.form-control:disabled:hover {
  background-color: var(--body-background) !important;
  border: 0.052vw solid var(--body-background) !important;
}

textarea {
  font-size: var(--font-size-16) !important;
}

.dropdown-toggle {
  font-size: var(--font-size-16) !important;
}

.createpagesBg {
  min-height: calc(100vh - 12vw);
  background-color: var(--color-white);
  padding-left: 2vw;
  padding-right: 2vw;
  border-bottom-left-radius: 2vw;
  border-bottom-right-radius: 2vw;
}

.vatNumber.customSelect.multiSelectDropdown__menu {
  width: fit-content !important;
}

.form-check-input:checked[type='radio'] {
  accent-color: var(--color-primary);
  background-color: var(--color-primary);
  border: 0.052vw solid var(--color-primary) !important;
}

.react-datepicker-wrapper input {
  min-height: 2.03vw !important;
}

.calendarSectionWrapper .react-datepicker-wrapper,
.employeeRegistrationHeight .react-datepicker-wrapper {
  width: 100%;
}

.editSalarycalendarSectionWrapper .calender-icon {
  right: 0 !important;
}

.editSalaryRadio {
  margin-top: 0.35vw !important;
}

.qr-code-img-wrapper {
  background-color: var(--body-background);
  padding: 2vw;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.reportIssue {
  display: flex;
}

/* For the entire scroll bar */
.PlanningTableContainer::-webkit-scrollbar,
.scrollBar::-webkit-scrollbar {
  width: 0.5vw;
  /* Width of the scrollbar */
  height: 0.5vw;
}

/* Track (background of the scrollbar) */
.PlanningTableContainer::-webkit-scrollbar-track,
.scrollBar::-webkit-scrollbar-track {
  background-color: var(--color-primary) !important;
  border-radius: 0.5vw;
}

/* Handle (draggable part of the scrollbar) */
.PlanningTableContainer::-webkit-scrollbar-thumb,
.scrollBar::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color) !important;
  border-radius: 0.5vw;
}

.multiSelectBgWhite .multiSelectDropdown__control {
  background-color: var(--color-white) !important;
}

.inputFieldColorWithBg {
  background-color: var(--color-white);
  border: 0.052vw solid var(--color-white) !important;
  min-height: 1.4vw !important;
  padding: 0.35vw 0.5vw !important;
}

.inputFieldColorWithBg:hover {
  border: 0.052vw solid var(--color-primary) !important;
  box-shadow: rgba(133, 113, 185, 0.3) 0px 0.052vw 0.104vw 0px, rgba(133, 113, 185, 0.25) 0px 0.052vw 0.156vw 0.052vw !important;

}

.vatNumber.customSelect.multiSelectDropdown__value-container {
  padding: 0 0.25vw !important;
}

.leftlabelClass {
  margin-top: 0.22vw !important;
}

.form-control:disabled,
.form-control:hover:disabled,
.multiSelectDropdown__control--is-disabled {
  background-color: #8571b94f !important;
  cursor: not-allowed !important;
}

.form-control:disabled {
  background-image: url("../images/LockIcon.svg");
  background-repeat: no-repeat;
  background-size: 0.6vw;
  background-position: right 0.5vw center;
}

.inputFieldPrefix * {
  font-size: var(--font-size-16) !important;
}

.multiSelectDropdown__control--is-disabled .multiSelectDropdown__dropdown-indicator {
  display: none;
}

.multiSelectDropdown__menu-list {
  max-height: 15vw !important;
}

.tab-subtitle {
  font-size: var(--font-size-19) !important;
}

.hourWrapper select,
.minuteWrapper select {
  background-color: var(--body-background);
  min-height: 2.03vw;
  border-radius: 0.5vw !important;
  cursor: pointer;
  font-size: var(--font-size-16) !important;
}

.multiSelectDropdown__value-container {
  padding-left: 0.25vw !important;
}

.multiSelectDropdown__multi-value__remove svg {
  width: 0.8vw !important;
  height: 0.8vw !important;
}

@media screen and (min-width:1024px) {
  .createpagesBgInvite {
    height: calc(100vh - 12vw) !important;
    min-height: calc(100vh - 12vw) !important;
    max-height: calc(100vh - 12vw) !important;
  }
}

.helpDangerIcon svg * {
  color: var(--color-danger) !important;
}

.fullWidthCalander .rmdp-container,
.fullWidthCalander .react-datepicker-wrapper {
  width: 100%;
}

.calendar-top-label.fullWidthCalander .calender-icon {
  top: 1.5vw !important;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  min-height: 2vw !important;
  padding: 0.25vw;
}

.p-multiselect-select-all,
.p-multiselect-filter-container {
  margin-right: 1vw !important;
}

.p-multiselect-filter-icon {
  transform: translateY(-50%) !important;
}

.p-multiselect-item {
  min-height: 2vw !important;
}

.p-multiselect-checkbox {
  line-height: 0 !important;
  margin-right: 1vw !important;
}

.city-item span {
  font-size: var(--font-size-16);
}

.p-multiselect-items-wrapper {
  min-height: 12vw !important;
  max-height: 12vw !important;
  height: 12vw !important;
}

.p-checkbox-box {
  width: 1vw !important;
  height: 1vw !important;
}

.p-checkbox {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

.p-multiselect-items {
  padding-left: 0 !important;
}

.p-multiselect-panel {
  padding-left: 1vw !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
  width: 1vw !important;
  height: 1vw !important;
}

.p-checkbox.p-highlight .p-checkbox-box {
  background-color: var(--color-primary) !important;
  border: 0.052vw solid var(--color-primary) !important;
  padding: 0.05vw !important;
}

.p-multiselect-header {
  margin-top: 0.5vw !important;
  margin-bottom: 0.5vw !important;
  background-color: transparent !important;
}

.p-multiselect-label.p-placeholder {
  font-size: var(--font-size-14);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: transparent !important;
}