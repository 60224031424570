@media screen and (min-width:0px) and (max-width:767px) {
  * {
    margin: 0;
    padding: 0;
    font-family: var(--poppins-light);
    font-size: 2.85vw;
    caret-color: var(--color-primary);
  }

  .multiSelectDropdown__dropdown-indicator {
    background-size: 4vw !important;
    padding: 3vw 4vw !important;
  }

  .form-select,
  .pcAccordion .accordion-button::after {
    background-size: 4vw !important;
  }

  .field-label,
  .field-label .pageSubTitle,
  .pcTitle,
  .modalWithoutTabs .bodyTitle,
  .modalWithoutTabs .modal-title,
  .bodyTitle,
  .linkText {
    font-size: 4.25vw !important;
  }

  .calendarWrapper abbr[title],
  .calendarWrapper .react-calendar__tile>span, .calendarWrapper .react-calendar__tile>abbr, .react-calendar__year-view__months__month>span, .react-calendar__decade-view__years__year, .react-calendar__century-view__decades__decade {
    font-size: 2.85vw !important;
  }

  .calendarWrapper .react-calendar__tile {
    padding: 2.495vw !important;
  }

  .calendarWrapper .react-calendar__month-view__weekNumbers .react-calendar__tile {
    padding: 2.6vw !important;
  }

  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-year-field,
  .multiSelectDropdown__placeholder,
  .plannedLocationHeader .poppins-semibold,
  .planningPlannedDate,
  .planningPlannedDate span,
  .calendarWrapper .react-calendar__navigation__label span,
  .planningEmployeeDetails *,
  .createpagesBgInvite .employeeRegisterErrorText, .createpagesBgInvite .employeeRegisterErrorText>*,
  .inputFieldPrefix *,
  .helpText,
  .dayDateWithIcon>*, .dayDateWithIcon .currentDay,
  .privacyText,
  .custom-multi-value span,
  .custom-option span,
  .reportIssue .fileNameUploaded .file-upload {
    font-size: 3.5vw !important;
  }

  input[type='text'].inputFieldColor, input[type='password'], input[type='email'], input[type='tel'], input[type='search'] {
    min-height: 9vw !important;
  }

  .multiSelectDropdown__control, .signInBtn,
  .autoCompleteWithSearchWrapper .p-autocomplete .autoCompleteInput {
    min-height: 9vw !important;
  }

  .resetBtn {
    min-height: 8vw;
    min-width: 10vw !important;
  }

  .modalSaveBtn,
  .form-control::placeholder,
  .form-control::-webkit-input-placeholder,
  .showPasswordText, .multiSelectDropdown__option,
  .multiSelectDropdown__single-value,
  input,
  .searchBtn, .hourWrapper select, .minuteWrapper select,
  .colonWrapper,
  textarea,
  .resetBtn,
  .cardTitle,
  .access-denied-content,
  .page-not-found-back-link,
  table td::before,
  table td,
  .noRecordsStyle,
  .text-danger,
  .location-manage-tabs button, .locationManagerCards *,
  .ck-toolbar__items *,
  .ck.ck-editor__main p, .ck.ck-editor__main p *,
  .pcAccordion .accordion-button.collapsed *,
  .pcAccordion .accordion-button *,
  .pcAccordion table td *,
  .autoCompleteWithSearchWrapper ::placeholder, .autoCompleteWithSearchWrapper ::-webkit-input-placeholder {
    font-size: 3vw !important;
  }

  .ck.ck-editor__main p, .ck.ck-editor__main p * {
    line-height: 5vw !important;
  }

  .ellipsis {
    font-size: 4vw !important;
  }

  .mobileFont,
  #lang-select,
  .TableHeader th,
  .pagination .page-num {
    font-size: 4.5vw !important;
  }

  .colonWrapper {
    width: 5%;
    text-align: center;
  }

  .hourWrapper, .minuteWrapper {
    width: 15%;
    text-align: center;
  }

  .value-color,
  .valueOption {
    font-size: 3vw !important;
  }

  .hourWrapper select, .minuteWrapper select {
    width: 100%;
    text-align: center;
    min-height: 8vw !important;
  }

  .searchBtn {
    min-width: 30vw !important;
    min-height: 8vw;
  }

  .calender-icon svg,
  .pagebackIcon svg,
  .pcAccordion .accordion-button::after {
    width: 5vw !important;
    height: 5vw !important;
  }

  .calender-icon {
    top: 0.8vw;
  }

  .vatVerifyBtn {
    width: 100%;
    height: 8vw;
  }

  .pagebackIcon {
    width: 8vw !important;
    height: 8vw !important;
  }

  .headerDateTime {
    font-size: 1vw !important;
  }

  .headerDateTime {
    font-size: 2.25vw !important;
  }

  .headerLogo svg {
    height: 6vw !important;
    width: 35vw !important;
  }

  .search-bar {
    top: 20vw !important;
    padding-bottom: 5vw !important;
  }

  .tab-subtitle {
    font-size: 4vw !important;
  }

  /* Login page */
  .loginLogo {
    width: 50vw !important;
    height: 20vw !important;
  }

  input[type='checkbox'] {
    width: 5vw !important;
    height: 5vw !important;
    margin-right: 1.5vw !important;
  }

  .forgotLinkWrapper {
    padding-bottom: 10vw !important;
    padding-top: 2.5vw !important;
  }

  .loginInText {
    padding-top: 4vw !important;
    padding-bottom: 4vw !important;
  }

  .signInBtn {
    margin-top: 8vw !important;
  }

  .loginWrapper div.loginCardPadding,
  .loginWrapper div.loginCardPaddingSecond {
    padding: 3vw 2.5vw 4vw !important;
  }

  .inputFieldColor {
    padding: 0.85vw !important;
  }

  /* Header */
  .profile-dropdown-links.dropdown-menu.show {
    width: 70vw !important;
  }

  .profile-dropdown-links .dropdown-item {
    margin-bottom: 3vw !important;
    min-height: 7vw !important;
  }

  .headerContainer {
    height: 20vw !important;
  }

  .headerContainer .imageBorder {
    width: 10vw !important;
    height: 9.5vw !important;
    background-size: 9.5vw !important;
  }

  .headerContainer .imageBorder img {
    width: 80% !important;
    height: 95% !important;
  }

  .notificationIcon,
  .multiSelectDropdown__multi-value__remove svg {
    width: 4vw !important;
    height: 4vw !important;
  }

  .headerLogo svg {
    height: 6vw !important;
    width: 40vw !important;
  }

  .iconWrapperMobile svg,
  .viewCompaniesBg svg {
    width: 6vw !important;
    height: 6vw !important;
  }

  .viewCompaniesBg {
    padding: 1vw !important;
  }

  .profileDropdownMenuIcon {
    width: 8vw !important;
    height: 8vw !important;
    padding: 1.25vw !important;
  }

  /* .addCompany svg {
    width: 10vw !important;
    height: 10vw !important;
  } */

  /* Pagination */
  .pagination-controls svg,
  .pagination svg,
  .fileUploadWrapper svg {
    width: 5vw !important;
    height: 5vw !important;
  }

  .pagination .btn.active, .pagination .previous-btn, .pagination .next-btn {
    width: 8.5vw !important;
    height: 8.5vw !important;
  }

  /* Access denied */
  .accessDeniedWrapper {
    height: calc(100vh - 17vw);
  }

  .access-denied-code {
    font-size: 14vw;
  }

  .access-denied-title {
    font-size: 7vw;
  }

  #lang-select {
    min-width: 17vw !important;
  }

  .dropdown-toggle {
    font-size: 3vw !important;
    border: 0.052vw solid !important;
    color: var(--color-primary) !important;
    margin-top: 3vw !important;
    margin-bottom: 2vw !important;
  }

  .modal.show .modal-dialog {
    min-width: 80% !important;
    max-width: 80% !important;
  }

  .locationCard img,
  .loadingIcon,
  .locationManagerProfile {
    width: 11vw !important;
    height: 11vw !important;
  }

  .locationImageUploaded img {
    width: 15vw !important;
    height: 15vw !important;
  }

  .multiSelectDropdown__menu-list {
    max-height: 40vw !important;
  }

  .location-manage-tabs {
    top: 39.8vw !important;
    margin-bottom: 2vw !important;
  }

  .modalWithoutTabs .btn-close {
    position: relative;
    right: -10%;
    top: 0px;
  }

  .createpagesBg {
    min-height: calc(100vh - 40vw) !important;
  }

  .locationManagerCards {
    margin-bottom: 2vw !important;
  }

  .csvReader {
    min-height: 20vw !important;
    max-height: 20vw !important;
  }

  .form-control:disabled {
    background-size: 2.6vw !important;
    background-position: right 1vw center !important;
  }

  .fieldSuffix {
    top: 8vw !important;
    right: 1vw !important;
    width: 14vw !important;
    height: 8vw !important;
    font-size: 3vw !important;
  }

  .employeeNameCardHover:hover, .employeeNameCardActive {
    box-shadow: rgba(0, 0, 0, 0.16) 0vw 0.56vw 0.513vw, rgba(0, 0, 0, 0.23) 0vw 0.56vw 1.313vw !important;
    border-radius: 1.5vw !important;
  }

  .calendarWrapper .react-calendar__navigation {
    height: 10vw !important;
    padding: 3vw !important;
  }

  .calendarWrapper .react-calendar__navigation__arrow {
    width: 4.8vw !important;
    min-width: 4.8vw !important;
    height: 4.8vw !important;
    font-size: 6vw !important;
  }

  .planningModal .react-calendar {
    width: 100% !important;
  }

  .myProfilePageWrapper .leftlabelClass,
  .companyData .leftlabelClass,
  .myProfilePageWrapper .col-8,
  .companyData .col-7 {
    width: 100%;
  }

  .addCompanyImageDashboard img {
    width: 13vw !important;
    height: 13vw !important;
  }

  .addCompanyModal.modal.show .modal-dialog {
    min-width: 83% !important;
    max-width: 83% !important;
  }

  .addCompanyModal .modal-body {
    max-height: calc(100vh - 30vw) !important;
  }

  .custom-multi-value img,
  .custom-option img {
    width: 6vw !important;
    height: 6vw !important;
    object-fit: cover;
  }

  .custom-option {
    min-height: 7vw !important;
    display: flex;
    margin-bottom: 1vw !important;
    height: 100% !important;
  }

  .multiSelectWrapper .custom-multi-value .close-button {
    width: 2.5vw !important;
    height: 2.5vw !important;
    background-size: 2.5vw !important;
  }

  .addAdministratorSearch .multiSelectDropdown__menu-list {
    max-height: 40vw !important;
  }

  .custom-option span {
    display: inline-block;
    width: 100%;
  }

  .ck-editor__editable_inline, .custom-ckeditor {
    min-height: 25vw;
  }

  .reportIssue .fileUploadWrapper input[type='file'] {
    height: 8.1vw !important;
  }

  .uploadedFile>span {
    max-width: 90% !important;
  }
}