.manageEmployeeTypes th:first-child {
  width: 60%;
}

.height-25.has-scroll {
  padding-right: 1vw;
}

@media screen and (min-width:1024px) {

  .height-25 {
    max-height: 25vw;
    min-height: 25vw;
  }

  .addEmployeeTypeClass {
    max-height: 27.5vw;
    overflow-y: auto;
  }
}