.location-manage-tabs button {
  border: 0 !important;
  color: var(--color-black);
  padding: 0vw 0 0.5vw;
  margin-right: 2vw;
}

.locationManagerBg {
  background-color: var(--color-white);
  border-radius: 2vw;
}

.location-manage-tabs {
  position: sticky;
  top: 10.1vw;
  background-color: var(--color-white);
  z-index: 999;
}

.locationManagerProfile {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  object-fit: cover;
}

.locationManagerCards * {
  font-size: var(--font-size-13);
  font-family: var(--poppins-medium);
  color: var(--color-primary);
}

.location-manage-tabs-dropdown .create-role-tabs {
  border: 0.052vw solid var(--color-primary) !important;
  background-color: var(--color-white) !important;
  margin: 0 0 2vw !important;
  font-size: 2vw !important;
  font-family: var(--poppins-medium) !important;
}

.location-manage-tabs-dropdown .dropdown-item.active, .location-manage-tabs-dropdown .dropdown-item:active {
  background-color: var(--color-white) !important;
  color: var(--color-primary) !important;
  font-size: 2vw !important;
  font-family: var(--poppins-medium) !important;
}

@media screen and (min-width:1024px) {
  .locationUsersHeight {
    max-height: calc(100vh - 25vw);
    overflow: auto;
  }

  .locationUsersHeight.has-scroll {
    padding-right: 1vw;
  }

}