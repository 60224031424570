.manageHolidayList {
    height: calc(100ch - 23vw);
    overflow: auto;
}

.manageHolidayList thead {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: var(--color-white);
}

.holidayEmportTable {
    max-height: calc(100vh - 35vw);
    overflow: auto;
}

.holidayEmportTable.has-scroll {
    padding-right: 1.5vw;
}

.holidayEmportTable th {
    width: 25%;
}