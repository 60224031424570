.DocumentsLeftWRapper.has-scroll {
  padding-right: 1vw !important;
}

@media screen and (min-width:1024px) {
  .DocumentsLeftWRapper {
    height: calc(100vh - 15vw);
    overflow: auto;
  }

}