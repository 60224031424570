.sectionli {
  width: 99%;
  margin: 0.5vw 0 0 !important;
}

.color-white {
  color: white !important;
}

.listtitle {
  color: black !important;
}

.listtitle:focus {
  z-index: 3;
  border-color: black !important;
  outline: 0;
  box-shadow: 0 0 0.25rem rgb(201 203 205 / 25%) !important;
}

#pills-pc-tab {
  background-color: var(--color-skyblue);
  color: var(--color-white);
  font-family: var(--font-family-poppins-regular);
  font-size: var(--font-size-m);
}

.tree .lev1 li::before {
  border-left: 0.052vw solid #999;
  bottom: 2.604vw;
  height: 100%;
  top: 0vw;
  width: 0.052vw;
  content: '';
  left: -2.5vw;
  position: absolute;
  right: auto;
}

.tree .lev1 li::after {
  color: pink;
  border-top: 0.052vw solid #999;
  height: 1.042vw;
  top: 2.188vw;
  width: 2.4vw;
  content: '';
  left: -2.4vw;
  position: absolute;
  right: auto;
}

.tree {
  position: relative;
}

.managepactions {
  text-align: center;
}

.addprojbtn {
  color: #000 !important;
  background: lightgray !important;
  border: none !important;
}

.pcid {
  width: 30px !important;
}

.salbenefitwidth {
  width: 45% !important;
}

.section_func_li {
  width: 100%;
  margin: 0.5vw 0 0 !important;
}