/* Paritair committee */
.editPcAction svg:hover use {
  fill: var(--color-primary);
}

.editPcAction svg use {
  fill: var(--color-black);
}

.pcAccordion .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg) !important;
}

.pcAccordion .accordion-button::after {
  background-image: url(../images/selectDropdown.svg) !important;
  background-size: 1vw;
  width: 1vw;
  transform: rotate(0deg) !important;
  height: 1vw;
}

.pcAccordion .accordion-button * {
  font-family: var(--poppins-medium);
  color: var(--color-primary);
  font-size: var(--font-size-15);
}

.pcAccordion .accordion-button.collapsed * {
  color: var(--color-black) !important;
  font-size: var(--font-size-15);
}

.pcAccordion table td * {
  font-size: var(--font-size-16);
}

.list-inline-item:not(:last-child) {
  margin-top: 0.5vw !important;
}

.fieldPrefix {
  top: 1.55vw;
  left: 0.07vw;
  border-top-left-radius: 0.4vw !important;
  border-bottom-left-radius: 0.4vw !important;
  width: 2vw;
  height: 1.95vw;
  background-color: var(--body-background);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fieldSuffix {
  top: 1.55vw;
  right: 0.15vw;
  border-top-right-radius: 0.4vw !important;
  border-bottom-right-radius: 0.4vw !important;
  width: 4vw;
  height: 1.93vw;
  background-color: var(--body-background);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-15);
}

.customModal .searchBtn,
.react-confirm-alert-button-group .searchBtn {
  min-width: auto !important;
}

.dashboardHeader .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg) !important;
}

.dashboardHeader .accordion-button::after {
  margin-right: auto !important;
  margin-left: 1vw;
  background-image: url(../images/accordionDownArrow.svg) !important;
  background-size: 1vw;
  width: 1vw;
  transform: rotate(0deg) !important;
  height: 1vw;
}

.createpagesBgInvite .employeeRegistrationHeight {
  padding-left: 0.08vw;
}

.eyeIcon {
  position: absolute;
  top: 0.3vw;
  right: 0.3vw;
}

.eyeIconPass {
  position: absolute;
  top: 0.6vw;
  right: 0.3vw;
}

.eyeIconPassword {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
}

.Toastify__toast-body {
  padding: 0.5vw !important;
}

.back-btn {
  font-family: var(--poppins-medium);
  font-size: var(--font-size-16);
}

#lang-select {
  min-width: 5vw;
  text-transform: uppercase;
  font-family: var(--poppins-medium) !important;
  border: 0.052vw solid var(--color-primary) !important;
}

.Toastify__toast-container {
  padding: 1vw !important;
  z-index: 100000 !important;
}

@media screen and (min-width:1024px) {
  .Toastify__toast-container {
    width: 18vw !important;
  }

  .Toastify__toast-icon {
    width: 1vw !important;
    height: 1vw !important;
    margin-right: 0.5vw !important;
  }

  .Toastify__close-button svg {
    width: 1vw !important;
    height: 1vw !important;
    align-self: center;
  }

}

@media screen and (min-width:768px) and (max-width:1023px) {
  .Toastify__toast-container {
    width: 35vw !important;
    font-size: 1.95vw !important;
  }

  .Toastify__toast-icon {
    width: 2.5vw !important;
    height: 2.5vw !important;
    margin-right: 1vw !important;
  }

  .Toastify__close-button svg {
    width: 2.5vw !important;
    height: 2.5vw !important;
    align-self: center;
  }

}

.planningTableWrapper .sortingWrapper {
  font-size: 1.5vw !important;
}