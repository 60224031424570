.modalWithoutTabs.modal {
  background-color: #ffffffb9;
  z-index: 1050 !important;
}

.modalWithoutTabs .modal-dialog {
  min-width: 40vw !important;
  max-width: 41vw !important;
  margin: auto;
}

.modalWithoutTabs .modal-content {
  border-radius: 2vw !important;
  border: 0vw !important;
  box-shadow: 0.365vw 0.365vw 0.26vw #000000bf;
}

.modalWithoutTabs .modal-header {
  border-top-left-radius: 1vw !important;
  border-top-right-radius: 1vw !important;
}

.modalWithoutTabs .modal-title {
  font-size: var(--font-size-15);
  font-family: var(--poppins-semibold);
}

.modalWithoutTabs .btn-close {
  position: relative;
  right: -2.865vw;
  top: 0px;
}

.modalWithoutTabs .modal-body {
  padding: 2vw !important;
}

.modalWithoutTabs .bodyTitle {
  font-size: var(--font-size-13) !important;
  font-family: var(--poppins-semibold);
}

.modalWithoutTabs .companyData .label {
  font-size: var(--font-size-14);
  font-family: var(--poppins-medium);
}

.inputFieldColor {
  min-height: 0.5vw !important;
  padding: 0.25vw !important;
  border-radius: 0.55vw !important;
  background-color: var(--body-background) !important;
  border: 0.1vw solid var(--body-background) !important;
}

.inputFieldColor:hover {
  background-color: var(--color-white) !important;
  border: 0.052vw solid var(--color-primary) !important;
  box-shadow: rgba(133, 113, 185, 0.3) 0px 0.052vw 0.104vw 0px,
    rgba(133, 113, 185, 0.25) 0px 0.052vw 0.156vw 0.052vw !important;
}

.inputFieldPrimary {
  min-height: 0.5vw !important;
  padding: 0.25vw !important;
  border-radius: 0.5vw !important;
  background-color: var(--color-primary) !important;
  border: 0.1vw solid var(--color-primary) !important;
  color: var(--color-white) !important;
}

/* For remove and delete buttons */
.removeBtn:hover svg use,
.deleteBtn:hover svg use {
  fill: var(--color-danger) !important;
}

/* For add buttons */
.addBtn:hover svg use {
  fill: var(--secondary-color) !important;
}

.addBtn svg use {
  fill: var(--color-primary);
}

.modalSaveBtn {
  color: var(--color-white);
  border: 0.1vw solid var(--color-primary) !important;
  background-color: var(--color-primary);
  font-size: var(--font-size-14);
  font-family: var(--poppins-medium);
  padding-left: 1vw;
  padding-right: 1vw;
  min-height: 1.2vw;
  border-radius: 0.65vw;
}

.modalSaveBtn:hover {
  background-color: var(--body-background);
  color: var(--color-primary);
}

.addCompanyModal .modal-body {
  max-height: calc(100vh - 11vw) !important;
  overflow: auto;
}

.addCompanyModal .modal-dialog {
  min-width: 50vw !important;
  max-width: 50vw !important;
}

.modalTitleConfirm {
  color: var(--color-primary) !important;
  font-family: var(--poppins-medium);
}

.customModal .btn-close {
  box-shadow: none !important;
}

/* React-confirm-alert */
.react-confirm-alert {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  background: #dad9dad2;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-confirm-alert-body {
  width: 50%;
  margin: auto;
  border-radius: 2vw !important;
  border: 0vw !important;
  box-shadow: 0.365vw 0.365vw 0.26vw #000000bf;
  min-height: 13vw;
  background-color: var(--color-white);
}

.planningModal .modal-body {
  padding: 1vw 2vw !important;
}

.planningModal .modal-dialog {
  margin-top: 1.5vw;
}

.reportAnIssueIcon svg * {
  color: var(--color-primary);
}

.reportAnIssueIcon:hover * {
  color: var(--secondary-color);
}

.modal.show .modal-dialog {
  min-width: 40% !important;
}

@media screen and (min-width:1024px) {
  .addCompanyImage {
    background-color: var(--body-background);
    border-radius: 50%;
    width: 5vw;
    height: 5vw;
  }
}

@media screen and (min-width:0px) and (max-width:767px) {
  .addCompanyImage {
    background-color: var(--body-background);
    border-radius: 50%;
    width: 13vw;
    height: 13vw;
  }

  .employeeRegistrationWrapper .leftlabelClass,
  .employeeRegistrationWrapper .col-8 ,
  .employeeRegistrationWrapper {
    width: 100% !important;
  }
}

@media screen and (min-width:768px) and (max-width:1023px) {
  .addCompanyImage {
    background-color: var(--body-background);
    border-radius: 50%;
    width: 8vw;
    height: 8vw;
  }

}

.transportSection {
  min-width: 20vw;
}

.transportSection .companyLanguage label {
  color: var(--color-black) !important;
}