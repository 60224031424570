/* PC time field */
.endTimeTimeWrapper input {
  padding: 0 0.5vw !important;
  border: 0.052vw solid var(--body-background) !important;
  border-radius: 0.5rem;
  cursor: text;
  width: 100%;
}

.endTimeTimeWrapper .MuiInputBase-formControl {
  outline: 0 !important;
  min-height: 0.5vw !important;
  padding: 0 !important;
  border-radius: 0.5rem !important;
  background-color: var(--body-background) !important;
  border: 0.052vw solid var(--body-background) !important;
}

.endTimeTimeWrapper fieldset {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.endTimeTimeWrapper input:hover {
  background-color: var(--color-white) !important;
  border: 0.052vw solid var(--color-primary) !important;
  box-shadow: rgba(133, 113, 185, 0.3) 0px 0.052vw 0.104vw 0px,
    rgba(133, 113, 185, 0.25) 0px 0.052vw 0.156vw 0.052vw !important;
}