@media screen and (min-width:0px) and (max-width:1023px) {
  body {
    padding: 0 3vw !important;
  }

  .hourWrapper select, .minuteWrapper select {
    background-color: var(--body-background);
  }

  .pageRightSideWrapper {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .loginWrapper {
    padding: 0 !important;
  }

  table.pwa thead {
    display: none;
  }

  table.pwa tr {
    height: auto !important;
    /* margin-bottom: 1rem; */
  }

  table.pwa tbody {
    display: block;
    width: 100%;
  }

  table.pwa,
  table.pwa tbody,
  table.pwa tr,
  table.pwa td {
    display: block;
    width: 100%;
    border: 0 !important;
  }

  table.pwa tbody tr {
    border: 0.052vw solid var(--color-primary) !important;
    border-radius: 0.5vw;
    margin-bottom: 2vw !important;
    padding: 1vw !important;
  }

  .boxShadow {
    border: 0.052vw solid var(--color-primary) !important;
    margin-bottom: 2vw !important;
    padding: 2vw 2vw 0 !important;
  }
  table td {
    text-align: right;
    padding-left: 53% !important;
    text-align: left !important;
    position: relative;
    word-break: break-word;
    min-height: 3rem;
  }

  table.pwa td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: 50%;
    text-align: left;
    color: var(--color-primary) !important;
  }

  .noRecordsStyle {
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .ActionTd {
    padding: 0 !important;
  }

  .categorySectionPwa {
    min-height: auto !important;
  }

  .shiftAddIcon .searchBtn {
    color: var(--color-white) !important;
  }
}