.weeklyCalendarWrapper .weeklyCalendar__navigation__label {
  margin: 0 !important;
  background-color: var(--color-primary);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.35vw;
  border: 0.052vw solid var(--color-primary);
  border-top-left-radius: 0.4vw;
  border-top-right-radius: 0.4vw;
}

.weeklyCalendarWrapper .weeklyCalendar__navigation__label span {
  font-family: var(--poppins-medium);
}

.weeklyCalendarWrapper .weeklyDaysContianers {
  display: flex;
  justify-content: space-between;
  margin-top: 0.15vw;
}

.weeklyCalendarWrapper .weeklyCalendar__tile {
  border: 0.15vw solid var(--color-white);
  background-color: var(--body-background);
  width: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.weeklyCalendarWrapper .weeklyCalendar__tile:first-child {
  border-left: 0 !important;
}

.weeklyCalendarWrapper .weeklyCalendar__tile:last-child {
  border-right: 0 !important;
}

.weeklyCalendarWrapper .weeklyCalendar__tile * {
  font-family: var(--poppins-medium);
  font-size: var(--font-size-12);
  color: var(--color-primary);
}

/* Type based modal popup */
.tooltip-inner {
  background-color: var(--body-background) !important;
  width: fit-content;
  max-width: fit-content;
  padding: 0.8vw;
  margin-left: 0.5vw;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--color-primary) !important;
  right: -0.417vw;
}

.tooltip-inner .locationName {
  font-family: var(--poppins-medium);
  color: var(--color-primary);
}

.weeklyCalendarWrapper .weeklyCalendar__tile.weeklyCalendar__tile--active {
  background-color: var(--color-primary);
}

.weeklyCalendarWrapper .weeklyCalendar__tile.weeklyCalendar__tile--active * {
  color: var(--color-white) !important;
}

.weeklyCalendarWrapper .weeklyCalendar__tile.dates-disabled {
  background-color: #89898986 !important;
  /* cursor: not-allowed; */
}

.weeklyCalendarWrapper .weeklyCalendar__tile.dates-disabled * {
  color: var(--color-white) !important;
  /* cursor: not-allowed; */
}

.locationCountBg {
  width: 1.8vw;
  height: 1.8vw;
  background-color: var(--body-background);
  color: var(--color-primary);
  border-radius: 50%;
  font-size: var(--font-size-14);
  font-family: var(--poppins-semibold);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-inner .active {
  background-color: var(--secondary-color) !important;
  border: 0.052vw solid var(--secondary-color) !important;
  color: var(--color-white) !important;
}

.employeeSchedulingTooltip.has-scroll {
  padding-right: 1vw;
}



@media screen and (min-width:1024px) {
  .tooltip-inner .locationName {
    font-size: var(--font-size-14);
  }

  .weeklyCalendarWrapper .weeklyCalendar__navigation__label span {
    font-size: var(--font-size-13);
  }

  .employeeSchedulingTooltip {
    max-height: 15vw;
    overflow-y: auto;
  }

}

@media screen and (min-width:768px) and (max-width:1023px) {
  .employeeSchedulingTooltip {
    max-height: calc(100vh - 50vw);
    overflow-y: auto;
  }

  .weeklyCalendarWrapper .weeklyCalendar__navigation__label span,
  .weeklyCalendarWrapper .weeklyCalendar__tile * {
    font-size: 1.95vw;
  }
  .weeklyCalendarWrapper .weeklyCalendar__tile {
    width: 10vw;
    height: 8vw;
  }
  .weeklyCalendarWrapper .weeklyCalendar__navigation__label {
    padding: 1vw !important;
  }
}