.employeeRegistrationWrapper {
  background-color: var(--color-white);
  border-radius: 0.5vw;
}

#employee-registration-tabs {
  padding: 0vw 1vw 1vw !important;
  position: sticky;
  top: 8.6vw;
  background-color: var(--color-white);
  z-index: 990;
  border-radius: 0.5vw;
}

#employee-registration-tabs.employeeRegistrationTabs {
  top: 5.1vw !important;
  padding: 1vw 1vw 1vw !important;
}

#employee-registration-tabs .nav-item .nav-link {
  margin-right: 2vw !important;
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 0.5vw !important;
  width: 100%;
  font-size: 0.95vw !important;
  font-family: var(--poppins-medium);
  padding: 0.35vw !important;
}

#employee-registration-tabs .nav-item .nav-link.active {
  background-color: var(--secondary-color);
}

#employee-registration-tabs .nav-item:last-child .nav-link {
  margin-right: 0 !important;
}

.employeeRegistrationWrapper .tab-content {
  padding: 0vw 1vw 1vw;
}

.employeeRegistrationWrapper .calender-icon .input-group-text {
  padding: 0.35vw !important;
}

.employeeBackLink {
  padding: 1vw 1vw 0;
  position: sticky;
  top: 5.1vw;
  background-color: var(--color-white);
  z-index: 999;
  height: 3.5vw;
  border-top-left-radius: 0.5vw !important;
  border-top-right-radius: 0.5vw !important;
}

.registerLink:hover {
  color: var(--secondary-color) !important;
}

.employeeRegisterErrorText,
.employeeRegisterErrorText>* {
  font-size: var(--font-size-16) !important;
}

.employeeCardIconStyle {
  display: none;
}

.yourPoolWrapper .employeeDetailsCardPlanning:hover .employeeCardIconStyle {
  display: flex;
}

.poolEmployeeStatus {
  font-size: var(--font-size-16);
}

.linkText {
  font-size: var(--font-size-16);
  font-family: var(--poppins-medium);
}

.linkText:hover {
  color: var(--secondary-color) !important;
}

.idHeight>div {
  min-height: 3.44vw;
}

@media screen and (min-width:768px) {
  #employee-registration-tabs {
    grid-template-columns: repeat(4, 1fr);
    gap: 1vw;
  }
}

@media screen and (min-width:1024px) {
  .createpagesBgInviteHeight {
    min-height: calc(100vh - 16vw) !important;
    max-height: calc(100vh - 16vw);
    overflow: auto;
    padding: 0 1vw 0vw 0vw;
  }

  .employeeRegistrationHeight {
    max-height: calc(100vh - 18vw);
    overflow: auto;
    padding: 0 1vw 0vw 0vw;
    min-height: calc(100vh - 30vw);
  }

  .employeeRegistrationWrapper {
    max-height: calc(100vh - 7vw);
    padding-bottom: 1vw;
  }
}


@media screen and (min-width:768px) and (max-width:1023px) {
  .employeeBackLink {
    top: 12.5vw;
    height: 6.5vw;
  }

  #employee-registration-tabs .nav-item .nav-link {
    font-size: 1.95vw !important;
    height: 100%;
    padding: 0.8vw !important;
  }

  #employee-registration-tabs {
    top: 19vw !important;
  }
}

@media screen and (min-width:0px) and (max-width:767px) {
  .employeeBackLink {
    top: 20vw;
    height: 10vw;
  }

  #employee-registration-tabs .nav-item .nav-link {
    font-size: 4vw !important;
    height: 100%;
    padding: 0.8vw !important;
  }

  #employee-registration-tabs {
    top: 30vw !important;
  }

  #employee-registration-tabs .nav-item:has(.nav-link.active) {
    display: block !important;
  }

  #employee-registration-tabs .nav-item {
    display: none !important;
  }
  .checkBoxContainer {
    min-width: 48vw !important;
  }
}

.marginBottom0 {
  margin-bottom: 0 !important;
}