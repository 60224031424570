.defaultCoefficientTableRightSection thead,
.defaultCoefficientTableLeftSection thead {
  position: sticky;
  top: 0;
  background-color: var(--color-white);
  z-index: 99;
}

.col-fixed {
  background-color: var(--color-white);
  left: 0;
  position: sticky;
  z-index: 101;
}

.defaultCoefficientHeight {
  height: calc(100vh - 22vw);
  overflow: auto;
}

.employee-type-coefficient {
  height: 3.15vw;
  padding: 0.5vw !important;
}

.table-second-part-tbody-tr td {
  padding: 0 !important;
  height: 3.15vw;
  position: relative;
}

.table-second-part-tbody-tr td input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0 !important;
}

.defaultCoefficientTableRightSection th {
  min-width: 10vw;
}

.firstpart-cell hr {
  transform: rotate(11deg);
  width: 102.5%;
  margin: 0;
  position: absolute;
  top: 3.2vw;
  left: -0.3vw;
}

.firstpart-cell {
  height: 100%;
  display: grid;
  position: relative;
}

@media screen and (min-width:768px) and (max-width:1023px) {
  .employee-type-coefficient {
    height: 6vw !important;
  }

  .table-second-part-tbody-tr td {
    height: 6vw !important;
  }

  .defaultCoefficientTableRightSection th {
    min-width: 25vw !important;
  }

  .firstpart-cell hr {
    transform: rotate(11.5deg);
    width: 102.5%;
    margin: 0;
    position: absolute;
    top: 5.7vw;
    left: -0.5vw;
  }

  .defaultCoefficientHeight {
    height: calc(100vh - 41vw);
  }

  .employeeTypeCoeffHeight {
    height: calc(100vh - 54vw);
  }
}

.warning {
  background-color: var(--body-background-color) !important;
  border-color: red !important;
}

.value-error {
  background-color: var(--secondary-color) !important;
  border-color: red !important;
}

.default-error {
  background-color: #d1ecf1 !important;
  border-color: #bee5eb !important;
}

.error-message {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  /* border-radius: 0.25rem; */
  /* background-color: #f8d7da; */
  /* border-color: #f5c6cb; */
}