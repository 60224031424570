.tradeTimePicker {
  margin-top: 0.3vw;
}

.fareForm {
  background-color: var(--color-white);
  border-bottom-left-radius: 2vw;
  border-bottom-right-radius: 2vw;
  min-height: calc(100vh - 14vw);
  padding-left: 2vw;
  padding-right: 2vw;
}

@media screen and (min-width: 1024px) {
  .vatVerifyBtn {
    margin-top: 0.4vw !important;
  }

  .tradeTimePicker .hourWrapper,
  .tradeTimePicker .minuteWrapper {
    width: 20%;
  }

  .tradeTimePicker .hourWrapper select,
  .tradeTimePicker .minuteWrapper select {
    width: 100%;
  }
}

.traderNextDate .calender-icon {
  right: 0 !important;
}

.tradeDashboard {
  height: calc(100vh - 6.1vw);
  display: grid;
  align-content: center;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .fareForm {
    min-height: calc(100vh - 25vw);
  }
  .tradeDashboard {
    height: calc(100vh - 19.1vw);
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .fareForm {
    min-height: calc(100vh - 30vw);
  }
  .tradeDashboard{
    height: calc(100vh - 30vw);
  }

  .welcomeDashboardWrapper{
    min-height: calc(100vh - 15vw);
  }
}

.pagination-controls span {
  font-family: var(--poppins-medium);
} 

.nowrap {
  white-space: nowrap;
}