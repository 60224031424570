.fileUploadWrapper input[type='file'],
.certificateUpload input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 2.8vw;
  width: 100%;
  cursor: pointer;
}

.fileUploadWrapper {
  cursor: pointer;
  width: fit-content;
}

.fileUploadWrapper .upload-button,
.certificateUpload .btn {
  color: var(--color-primary) !important;
  border: 0 !important;
  background-color: var(--color-white) !important;
  min-height: 2.344vw;
  padding: 0.2vw 1vw !important;
  font-size: var(--font-size-16);
  border-radius: 0.4vw;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadWithBorder .upload-button {
  border: 0.052vw solid var(--color-primary) !important;
}

.uploadWithBorder .fileUploadWrapper:hover .upload-button {
  border: 0.052vw solid var(--secondary-color) !important;
}

.fileUploadWrapper:hover .upload-button,
.certificateUpload:hover .btn {
  color: var(--secondary-color) !important;
}

.fileUploadWrapper *,
.fileUploadWrapper .form-button * {
  cursor: pointer;
}

.fileUploadWrapper svg {
  width: 1vw;
  height: 1vw;
}

.candidateDownload svg,
.certificateUpload svg {
  width: 1.25vw;
  height: 1.25vw;
}
.modal .fileUploadWrapper {
  margin: auto;
}
::-webkit-file-upload-button {
  cursor: pointer;
}

/* Report issue */

.reportIssue label {
  display: none;
}

.reportIssue .fileUploadWrapper input[type='file'] {
  height: 2.1vw !important;
}

.reportTextArea textarea:hover {
  border: 0 !important;
  box-shadow: none !important;
}

.sendText * {
  font-size: var(--font-size-16);
}

.sendText {
  color: var(--color-primary);
}

.sendText:hover {
  color: var(--secondary-color);
}


.reportIssue .fileNameUploaded {
  justify-content: start !important;
  color: var(--color-primary);
  font-family: var(--poppins-medium);
  padding-bottom: 0.5vw !important;
}

.reportIssue .fileNameUploaded .file-upload{
  font-family: var(--poppins-medium);
  margin-right: 1vw !important;
  font-size: var(--font-size-15);
}

.reportIssue .upload-button {
  padding-left: 0 !important;
}

.reportIssue .upload-button {
  min-height: 2.1vw !important;
}

@media screen and (min-width:768px) and (max-width:1023px) {
  .reportIssue > div {
    margin: 1vw 0 !important;
  }
  .reportIssue .fileUploadWrapper input[type='file'] {
    height: 5vw !important;
  }
}