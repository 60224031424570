.timeRegistrationTable .shiftDate {
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
  background-color: var(--color-primary) !important;
  color: var(--color-white);
  padding: 0.5vw;
}

.timeRegistrationTable tr td:nth-child(1) * {
  font-size: var(--font-size-12);
  font-family: var(--poppins-medium);
}

.timeRegistrationTable .shiftNumber {
  color: var(--color-primary);
  font-size: var(--font-size-13);
  font-family: var(--poppins-extralight);
}

.timeRegistrationTableHeader .categorySection * {
  color: var(--color-primary);
  font-size: var(--font-size-14);
  font-family: var(--poppins-extralight);
}

.timeRegistrationTabs .nav-item a {
  color: var(--color-white);
  border: 0.1vw solid var(--color-primary) !important;
  background-color: var(--color-primary);
  font-size: var(--font-size-14);
  font-family: var(--poppins-medium);
  min-height: 1vw;
  border-radius: 0;
  min-width: 8vw;
  text-align: center;
}

.timeRegistrationTabs .nav-item .active {
  background-color: var(--secondary-color);
  border: 0.052vw solid var(--secondary-color) !important;
  color: var(--color-white);
}

.timeRegistrationTabs .nav-item:first-child a {
  border-top-left-radius: 0.4vw;
  border-bottom-left-radius: 0.4vw;
}

.timeRegistrationTabs .nav-item:last-child a {
  border-top-right-radius: 0.4vw;
  border-bottom-right-radius: 0.4vw;
}

.timeRegistrationPerEmployee.has-scroll {
  padding-right: 1vw !important;
}

.timeRegistrationDateWrapper {
  background-color: var(--body-background);
  border: 0.052vw solid var(--body-background);
  border-radius: 0.4vw;
  color: var(--secondary-color);
}

.totalValidatedHours {
  background-color: var(--body-background);
  font-size: var(--font-size-13);
  border: 0.052vw solid var(--body-background);
  border-radius: 0.5vw;
  min-width: 12vw;
  text-align: left;
  padding-left: 0.3vw;
  min-height: 1.4vw;
  display: inline-flex !important;
  align-items: center;
  justify-content: start;
  color: var(--color-primary);
  font-family: var(--poppins-medium);
}

.processedWeekNumber .weekNumber {
  font-size: var(--font-size-30);
  font-style: normal;
  font-family: var(--poppins-medium);
}

.timeRegistrationWrapper .employeeDetailsCardPlanning {
  box-shadow: rgba(0, 0, 0, 0.16) 0vw 0.156vw 0.313vw,
    rgba(0, 0, 0, 0.23) 0vw 0.156vw 0.313vw;
  padding: 0.5vw !important;
}

.timeRegistrationEmployeeCard .empDetails > div {
  width: 6.5vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .timeRegistrationTabs .nav-item a,
  .totalValidatedHours {
    font-size: 1.95vw !important;
  }

  .totalValidatedHours {
    padding: 0.65vw !important;
  }

  .timeRegistrationPerEmployee {
    height: calc(100vh - 35vw);
    overflow: auto;
  }
}

@media screen and (min-width: 1024px) {
  .timeRegistrationPerEmployee {
    height: calc(100vh - 17vw);
    overflow: auto;
  }

  .timeRegistrationEmployeeCard .employeeName {
    font-size: var(--font-size-16) !important;
    font-family: var(--poppins-semibold);
  }
  .timeRegistrationPerEmployee .form-control:disabled {
    background-size: 0.4vw !important;
    background-position: right 0.25vw center !important;
  }
}

.timeRegistrationDate {
  background-color: var(--color-primary) !important;
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
}

.timeRegistrationValidation * {
  font-size: 0.75vw !important;
}

.timeRegistrationValidationSend svg {
  width: 0.75vw !important;
  height: 0.75vw !important;
}

.timeRegistrationTable tbody td {
  padding: 0.3vw 0.85vw !important;
}

.birthday {
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
  border-top-right-radius: 0.4vw;
  border-bottom-right-radius: 0.4vw;
  background-color: var(--secondary-color) !important;
  color: var(--color-white);
  padding: 0.2vw;
  background-image: url("../../../static/images/Calendar.svg");
  background-position: top 0.25vw left 0.7vw;
  background-repeat: no-repeat;
  background-size: 0.9vw;
}


.timeRegistrationClosed {
  background-color: var(--color-primary);
  width: fit-content;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  font-size: 0.7vw;
  color: var(--color-white);
}

.totalText {
  font-size: 0.8vw !important;
}