:root {
  /* colors */
  --color-white: #fff;
  --color-black: #000;
  --body-background: #eeecf3;
  --color-primary: #8571b9;
  --secondary-color: #87abb8;
  --color-yellow: #ede681;
  --border-color: #898989;
  --color-danger: #ff0000;
  --next-month-bg: #CBCBCB;
  --dark-grey: #4C4D55;
  --tabs-bg-color: #4c4d550f;
  --color-green: #4CAF50;
  --color-dark-pink: #e55496;


  /* Fonts */
  --poppins-medium: 'poppins-medium';
  --poppins-extralight: 'poppins-extralight';
  --poppins-light: 'poppins-light';
  --poppins-regular: 'poppins-regular';
  --poppins-bold: 'poppins-bold';
  --poppins-semibold: 'poppins-semibold';
  --poppins-italic: 'poppins-italic';

  /* fonts */
  --font-size-10: 0.55vw;
  --font-size-11: 0.6vw;
  --font-size-12: 0.65vw;
  --font-size-13: 0.7vw;
  --font-size-14: 0.75vw;
  --font-size-15: 0.8vw;
  --font-size-16: 0.85vw;
  --font-size-17: 0.93vw;
  --font-size-19: 0.99vw;
  --font-size-20: 1.042vw;
  --font-size-21: 1.094vw;
  --font-size-22: 1.146vw;
  --font-size-23: 1.198vw;
  --font-size-24: 1.25vw;
  --font-size-30: 1.6vw;
  --font-size-38: 1.979vw;
  --font-size-100: 5.208vw;
  --invoice-item-min-height: 0.5vw;
}

@font-face {
  font-family: 'poppins-regular';
  src: url('../fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'poppins-bold';
  src: url('../fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'poppins-semibold';
  src: url('../fonts/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'poppins-italic';
  src: url('../fonts/Poppins/Poppins-Italic.ttf');
}

@font-face {
  font-family: 'poppins-medium';
  src: url('../fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'poppins-light';
  src: url('../fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'poppins-extraLight';
  src: url('../fonts/Poppins/Poppins-ExtraLight.ttf');
}

.colorSkyBlue {
  color: var(--secondary-color);
}

.colorPrimary {
  color: var(--color-primary);
}

.poppins-semibold {
  font-family: var(--poppins-semibold);
}

.colorDarkPurple {
  color: var(--dark-grey);
}

.poppins-medium {
  font-family: var(--poppins-medium);
}
