.welcomeDashboardWrapper {
  min-height: calc(100vh - 10.19vw);
  display: grid;
  align-items: center;
}


.welcomeText {
  font-size: var(--font-size-17);
  font-family: var(--poppins-medium);
  padding-bottom: 3vw;
}

.welcomeDashboardWrapper>div {
  padding: 3vw;
}

.companyWrapperGrid {
  margin-right: 1vw;
}

.addCompany button:hover svg {
  background-color: var(--color-primary) !important;
}

.companyName {
  font-family: var(--poppins-medium);
  font-size: var(--font-size-15);
  letter-spacing: 0.1vw;
  white-space: break-spaces;
}

.addCompanyImageDashboard {
  padding-bottom: 1vw;
}

.addCompanyImageDashboard img {
  object-fit: cover;
}

.delete-icon {
  color: var(--color-primary) !important;
  left: 5vw;
  bottom: 0;
}

.deleteIcon {
  background: var(--color-white);
  width: 2vw;
  height: 2vw;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center !important;
  border-radius: 50%;
  box-shadow: rgb(133, 113, 185) 0.052vw 0vw 0.32vw 0.02vw;
  cursor: pointer;
}

.userIconPresent {
  margin-left: 0.2vw;
}

.userIconPresent img {
  object-fit: cover;
  box-shadow: rgb(133, 113, 185) 0.052vw 0vw 0.32vw 0.02vw;
}

/* Location dashboard */
.locationDashboardAccordionData .accordion-body {
  padding: 0 !important;
}

.accordion .locationDashboardAccordionData {
  background-color: var(--body-background) !important;
  padding: 0 !important;
}

.emptySchedule {
  color: var(--secondary-color) !important;
}

@media screen and (min-width:1024px) {
  .companyWrapper {
    height: calc(100vh - 18vw);
    overflow: auto;
  }
  .companyData label {
    font-size: var(--font-size-14) !important;
  }
  
}