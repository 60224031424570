@media screen and (min-width:1024px) {
  .custom-toggle-container>div {
    width: 2.5vw !important;
  }

  .custom-toggle-container>div>div:first-child {
    width: 2.5vw !important;
    height: 1vw !important;
    border-radius: 0.5vw !important;
  }

  .custom-toggle-container>div>div:not(:first-child)>div {
    width: 1vw !important;
    height: 1vw !important;
  }

  .custom-toggle-container.active>div>div:not(:first-child)>div {
    left: 1.5vw !important;
  }

  .custom-toggle-container>div>div:first-child div {
    font-size: 0.5vw !important;
    left: 0.15vw !important;
    height: 1vw !important;
    width: 1.5vw !important;
  }
}

@media screen and (min-width:768px) and (max-width:1023px) {
  .custom-toggle-container>div>div:first-child {
    width: 7.5vw !important;
    height: 3vw !important;
    border-radius: 1.5vw !important;
  }

  .custom-toggle-container>div>div:first-child div {
    font-size: 0.5vw !important;
    left: 0.15vw !important;
    height: 2vw !important;
    width: 5.5vw !important;
    font-size: 2vw !important;
  }

  .custom-toggle-container>div {
    width: 7.5vw !important;
  }

  .custom-toggle-container>div>div:not(:first-child)>div {
    width: 3vw !important;
    height: 3vw !important;
  }

  .custom-toggle-container.active>div>div:not(:first-child)>div {
    left: 4.5vw !important;
  }

}