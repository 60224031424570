.sidebarContent {
  padding-top: 1.3vw !important;
  padding-bottom: 0.6vw !important;
}

.sidebarTitle {
  font-family: var(--poppins-medium);
  font-size: var(--font-size-14);
  padding: 1vw 0.5vw 0vw;
}

.sidebarBorder {
  border-bottom: 0.052vw solid var(--color-white) !important;
}

.sideBar {
  position: fixed;
  width: 8vw;
  height: 100vh;
  transition: width 0.3s ease;
  z-index: 9999;
  background-color: var(--color-primary);
  margin-bottom: 1vw;
}

.submenuItem:not(:last-child) {
  border-bottom: 0.15vw solid #8571b947;
}

.submenuItem {
  padding-left: 4.5vw;
  padding-right: 4.5vw;
}

.sideBar .sidebarItemWrapper.active  {
  color: var(--color-yellow) !important;
}

.sideBar .active svg use {
  fill: var(--color-yellow) !important;
}

.sideBar svg use {
  fill: var(--color-white);
}

.sideBar {
  color: var(--color-white);
}
.sideBar #dashboard {
  stroke: var(--color-white) !important;
}

.sideBar .active #dashboard {
  stroke: var(--color-yellow) !important;
}

.sidebarItemWrapper:last-child{
  padding-bottom: 2vw;
}

.sideBarIcon {
  width: 2vw !important;
  height: 2vw !important;
}