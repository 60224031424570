.locationImageUpload .fileNameUploaded {
  justify-content: start !important;
}

.locationImageUploaded .fileNameUploaded .certificate-name {
  display: none !important;
}

.locationImageUploaded .fileNameUploaded .fileUploadDeleteBtn {
  position: absolute;
  bottom: 0vw;
  right: -2.2vw;
  background: var(--color-white);
  width: 2vw;
  height: 2vw;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center !important;
  border-radius: 50%;
  box-shadow: rgb(133, 113, 185) 0.052vw 0vw 0.32vw 0.02vw;
  cursor: pointer;
}

.locationImageUploaded .fileUploadLoading {
  position: absolute;
  bottom: 0.8vw;
  right: -4vw;
}

.locationImageUpload .upload-button {
  padding-left: 0 !important;
}

.locationRadioBtn {
  min-width: 11vw;
  text-align: center;
  padding: 0.5vw;
}

.locationRadioBtn:hover,
.selectedLocation,
.checkBoxAdminContainer:hover,
.selectedCompanies {
  box-shadow: rgb(133, 113, 185) 0.052vw 0vw 0.32vw 0.02vw;
  border-radius: 1vw;
  cursor: pointer;
}

.missedCompanies {
  box-shadow: rgb(224, 24, 17) 0.052vw 0vw 0.32vw 0.02vw;
  border-radius: 1vw;
  cursor: pointer;
}

@media screen and (min-width:1024px) {
  .managelocationBg {
    min-height: calc(100vh - 11vw) !important;
  }

  .grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width:768px) and (max-width:1023px) {
  .locationRadioBtn {
    min-width: 30vw !important;
  }

  .locationRadioBtn img {
    width: 8vw !important;
    height: 8vw !important;
  }

  .locationImageUploaded img {
    width: 10vw !important;
    height: 10vw !important;
  }

  .locatopnCompanyCard svg {
    width: 4vw !important;
    height: 4vw !important;
  }

  .locationImageUploaded .fileNameUploaded .fileUploadDeleteBtn {
    right: -5.5vw !important;
  }

  .locationImageUploaded .fileNameUploaded .fileUploadDeleteBtn {
    width: 5vw !important;
    height: 5vw !important;
  }

  .locationImageUploaded .fileNameUploaded .fileUploadDeleteBtn svg {
    width: 2.5vw !important;
    height: 2.5vw !important;
  }
}

@media screen and (min-width:0px) and (max-width:767px) {

  .locationImageUploaded .fileNameUploaded .fileUploadDeleteBtn {
    width: 8vw !important;
    height: 8vw !important;
  }

  .locationImageUploaded .fileNameUploaded .fileUploadDeleteBtn {
    right: -8.5vw !important;
  }

  .locationImageUploaded .fileNameUploaded .fileUploadDeleteBtn svg {
    width: 4.5vw !important;
    height: 4.5vw !important;
  }
}

.button-underline a{
  text-decoration: none !important;
}

.locationUsersHeight {
  gap: 1vw;
}