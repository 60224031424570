* {
  margin: 0;
  padding: 0;
  font-family: var(--poppins-light);
  font-size: 1vw;
  caret-color: var(--color-primary);
}

body,
.bodyBg {
  background-color: var(--body-background) !important;
}

.contentBg {
  background-color: var(--color-white);
  border-radius: 2vw;
  padding: 2vw;
}

.manageContentBg {
  background-color: var(--color-white);
  border-radius: 2vw;
  padding: 2vw;
}

.managepagesBg {
  background-color: var(--color-white);
  padding: 2vw;
  padding-top: 0 !important;
  border-bottom-left-radius: 2vw;
  border-bottom-right-radius: 2vw;
  min-height: calc(100vh - 15vw);
}

.managePagesBg {
  background-color: var(--color-white);
  padding: 2vw;
  padding-top: 0 !important;
  border-bottom-left-radius: 2vw;
  border-bottom-right-radius: 2vw;
  min-height: calc(100vh - 20vw);
}

.manageSalaryBenefits {
  background-color: var(--color-white);
  padding: 2vw;
  padding-top: 0 !important;
  border-bottom-left-radius: 2vw;
  border-bottom-right-radius: 2vw;
  min-height: calc(100vh - 20vw);
}

.search-bar {
  background-color: var(--color-white);
  padding: 2vw;
  padding-bottom: 0 !important;
  border-top-right-radius: 2vw;
  border-top-left-radius: 2vw;
  position: sticky;
  top: 5.1vw;
  z-index: 999;
}

.cursor-pointer {
  cursor: pointer;
}

/* Footer */
.footerContainerBg {
  background-color: var(--color-primary);
  height: 4.167vw;
}

.footerContainerBg .d-grid {
  grid-template-columns: auto auto auto auto auto;
}

.footerContent {
  font-size: var(--font-size-15);
  font-family: var(--poppins-medium);
  line-height: 1.6vw;
}

.poweredBy {
  font-size: var(--font-size-11);
  font-family: var(--poppins-medium);
}

/* Header */

.headerDateTime {
  font-size: var(--font-size-14);
}

.dateTimePosition {
  bottom: -0.7vw !important;
}

.imageBorder {
  background: url('../images/profileCircle.svg') no-repeat center center;
  width: 3.5vw;
  height: 4vw;
  background-size: 3.5vw;
}

.overlayImageBorder {
  width: 3.5vw;
  height: 4vw;
}

.profile-dropdown-links {
  padding: 1vw !important;
}

.profile-dropdown-links .contentBg {
  padding: 0 !important;
  border-radius: 1vw !important;
}

.profile-dropdown-links .contentBg:first-child {
  padding-bottom: 0.25vw !important;
}

.profile-dropdown-links.dropdown-menu.show {
  width: 18vw;
  background-color: var(--body-background);
  border: 0 !important;
  position: fixed !important;
  top: 13% !important;
  right: 1% !important;
  /* Fixed the typo here */
  transform: translate(-13%, -3%) !important;
}

.profile-dropdown-links .imageBorder {
  width: 2vw;
  height: 2vw;
  background-size: 1.95vw;
  position: relative;
}

.profile-dropdown-links .imageBorder img {
  position: absolute;
  left: 0.18vw;
  top: 0.21vw;
}

.profile-dropdown-links .dropdown-item {
  display: flex;
  align-items: center;
  border: 0.052vw solid var(--color-white);
  border-radius: 1vw;
  min-height: 3vw;
  margin-bottom: 0.2vw;
  padding: 0.25vw 0.5vw !important;
}

.profileDropdownMenuIcon {
  padding: 0.3vw;
}

.profile-dropdown-links .dropdown-item:focus,
.profile-dropdown-links .dropdown-item:hover,
.profile-dropdown-links .dropdown-item.active {
  border: 0.052vw solid var(--color-primary);
  background-color: var(--color-white);
}

.viewCompaniesBg {
  background-color: var(--body-background);
  border-radius: 0.4vw;
  padding-right: 0.5vw;
}

.overlayEffect {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.profileDropdownOpen .dropdown {
  z-index: 9999;
  position: fixed;
  top: 0.5vw !important;
  right: 2.75vw !important;
}

.headerWrapper .dropdown-toggle::after {
  display: none;
}


.actionWrapper svg use {
  fill: var(--dark-grey);
  cursor: pointer;
}

.actionWrapper svg:hover * {
  fill: var(--color-primary) !important;
}

.removeUploadedFile:hover svg * {
  fill: var(--color-danger) !important;
}

.removeUploadedFile svg *,
.secondaryColorHoverEffect * {
  fill: var(--color-primary);
}

.secondaryColorHoverEffect:hover *,
.linkWrapper:hover .secondaryColorHoverEffect * {
  fill: var(--secondary-color) !important;
  cursor: pointer;
}

.secondaryHoverEffectText:hover {
  color: var(--secondary-color);
}

/* Button */

.saveBtn {
  color: var(--color-white);
  border: 0.1vw solid var(--color-primary) !important;
  background-color: var(--color-primary);
  font-size: var(--font-size-14);
  font-family: var(--poppins-medium);
  padding-left: 1vw;
  padding-right: 1vw;
  min-height: 1.2vw;
  border-radius: 0.5vw;
}

.saveBtn:hover,
.yesBtn:hover {
  background-color: var(--secondary-color);
  color: var(--color-white);
  border: 0.1vw solid var(--secondary-color) !important;
}

.yesBtn {
  color: var(--color-white);
  border: 0.1vw solid var(--color-primary) !important;
  background-color: var(--color-primary);
  font-size: var(--font-size-14);
  font-family: var(--poppins-medium);
  padding-left: 1vw;
  padding-right: 1vw;
  min-height: 1.4vw;
}

.noBtn {
  color: var(--color-primary);
  border: 0.1vw solid var(--body-background) !important;
  background-color: var(--body-background);
  font-size: var(--font-size-14);
  font-family: var(--poppins-medium);
  padding-left: 1vw;
  padding-right: 1vw;
  min-height: 1.4vw;
}

.noBtn:hover {
  background-color: var(--color-primary);
  color: var(--body-background);
}

.headerWrapper {
  position: sticky;
  top: 0;
  background-color: var(--body-background);
  z-index: 998;
  height: 5.1vw;
}

.profileDropdownOpen {
  height: 5.1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profileDropdownOpen .completeWidth {
  width: 100%;
}

.headerWrapperWithFilter {
  position: sticky;
  top: 0;
  background-color: var(--body-background);
  z-index: 998;
}

.positionSticky {
  position: sticky;
  background-color: var(--body-background);
  z-index: 997;
}

/* Dashboard accordion */
.dashboardAccordionHeader {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
  border-top-left-radius: 1.3vw !important;
  border-top-right-radius: 1.3vw !important;
}

.dashboardAccordionTitle {
  font-size: var(--font-size-17);
}

.locationDashboard .accordion-header {
  background-color: var(--body-background);
}

.locationDashboard .accordion-button::after,
.cooperationAgreementWrapper .accordion-button::after,
.dashboardAccordionHeader.accordion-button::after {
  background-image: url('../images/accordionDownArrow.svg') !important;
}

.locationDashboard .accordion-button:not(.collapsed)::after,
.cooperationAgreementWrapper .accordion-button:not(.collapsed)::after,
.dashboardAccordionHeader.accordion-button:not(.collapsed)::after {
  background-image: url('../images/accordionUpArrow.svg') !important;
  transform: rotate(0) !important;
}

.employeeRegistration label {
  font-size: var(--font-size-12);
  font-family: var(--poppins-regular);
}

.helpText {
  font-size: var(--font-size-13);
  font-family: var(--poppins-medium);
}

.tab-title {
  font-size: var(--font-size-17) !important;
  font-family: var(--poppins-semibold) !important;
  color: var(--color-primary);
}

.pageTitle {
  font-size: var(--font-size-20) !important;
  font-family: var(--poppins-semibold) !important;
  color: var(--color-primary);
}

.checkBoxLabel {
  color: var(--color-primary);
  font-size: var(--font-size-15) !important;
}

.addEmployeeToCompany {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 0;
}

.addEmployeeToCompany input[type='checkbox'],
.addAdministratorWrapper input[type='checkbox'] {
  margin: auto !important;
  margin-bottom: 1vw !important;
  border-radius: 0.3vw !important;
}

.calender-icon {
  top: 0;
  right: 0.35vw;
}

.calender-icon svg {
  color: var(--color-primary);
}

.equal-cols {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.equal-cols>[class*='col'] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.equal-cols>[class*='col']>* {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

/* Popover */
.popover {
  --bs-popover-bg: var(--body-background) !important;
  width: max-content;
  border: 0px !important;
  --bs-popover-arrow-width: 2vw !important;
  --bs-popover-arrow-height: 1.5vw !important;
  --bs-popover-max-width: 10vw !important;
}

.popover-body {
  padding: 0 !important;
}

.bs-popover-end>.popover-arrow::before {
  visibility: hidden;
}

.bs-popover-end>.popover-arrow::after {
  left: 0.2vw !important;
}

.overlay-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1040;
}

.adminDashboard a .card {
  min-height: 5vw;
}

.adminDashboard a:hover .card {
  box-shadow: rgba(113, 113, 185, 0.8) 0px 2px 8px 0px;
}

.cardTitle {
  color: var(--color-primary);
  font-size: var(--font-size-17);
  transition: font-size 0.3s ease;
  font-family: var(--poppins-medium);
}

.adminDashboard a:hover .cardTitle {
  font-size: var(--font-size-20);
  font-family: var(--poppins-semibold);
}

/* My account */

.privacyText {
  font-size: var(--font-size-14);
  color: var(--dark-grey);
  font-family: var(--poppins-extralight);
}

.myAccountCardTitle {
  font-size: var(--font-size-21) !important;
  font-family: var(--poppins-semibold);
}

.lockIcon {
  right: 1.5vw;
  top: 0.25vw;
}

.profilePlanningTable th {
  color: var(--color-primary) !important;
  font-family: var(--poppins-light) !important;
  font-size: var(--font-size-13);
  font-weight: normal;
  text-align: center;
}

.profilePlanningTable td {
  text-align: center;
  vertical-align: middle;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.profilePlanningTable .hourWrapper,
.profilePlanningTable .minuteWrapper {
  width: 2vw;
  height: 1.8vw;
}

.profilePlanningTable .timeWrapper * {
  background-color: var(--body-background);
  border: 0.052vw solid var(--body-background);
}

.profilePlanningTable .hourWrapper select,
.profilePlanningTable .minuteWrapper select {
  min-width: 1.5vw !important;
  max-width: 1.6vw !important;
}

.profilePlanningTable .hourWrapper select:focus,
.profilePlanningTable .minuteWrapper select:focus {
  border: 0.052vw solid var(--body-background) !important;
}

.profilePlanningTable .totalHours {
  height: 1.8vw !important;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Pagination */

.pagination .page-num {
  min-width: 2.5vw;
  color: var(--color-black);
  font-size: 1vw !important;
  font-family: var(--poppins-light);
}

.pagination .previous-btn.disabled,
.pagination .next-btn.disabled {
  background-color: var(--dark-grey) !important;
  fill: var(--color-white) !important;
  pointer-events: unset !important;
  cursor: not-allowed;
}

.pagination .btn svg path {
  fill: var(--color-white) !important;
}

.pagination .btn.active,
.pagination .previous-btn,
.pagination .next-btn {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
  border-radius: 50% !important;
  width: 2.083vw;
  height: 2.083vw;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-active,
.pagination .page-num:hover {
  color: var(--color-primary);
  font-family: var(--poppins-medium);
}

.pagination .page-num svg {
  display: none;
}

.pagination svg {
  width: 1vw !important;
  height: 1vw !important;
}

/* Overlay */
.fullscreen-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.26vw);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.fullscreen-loading-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.3);
  z-index: -1;
}

@media screen and (min-width:1024px) {
  .myProfilePageWrapper {
    height: calc(100vh - 8.5vw) !important;
    overflow: auto;
  }
}

/* 
.mb-3 .table::before {
  position: absolute;
  top: 50%;
  width: 1vw;
  height: 1vw;
} */

.editEmailTemplate {
  max-height: calc(100vh - 20vw);
  overflow: auto;
}