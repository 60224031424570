.headSticky {
  position: sticky;
  top: 0;
}

.manageFunctionsTable.has-scroll,
.manageCategoriesTable.has-scroll,
.managePcTable.has-scroll,
.editPcWrapeer.has-scroll,
.ageWrapper.has-scroll {
  padding-right: 1vw;
}

.editPcWrapeer .addBtn,
.pcTitle {
  position: sticky;
  top: 0vw;
  background-color: var(--color-white);
  z-index: 99;
}

.pcTitle {
  width: 100%;
}

@media screen and (min-width:1024px) {

  .manageCategoriesTable,
  .manageFunctionsTable,
  .managePcTable {
    max-height: 25vw;
    min-height: 25vw;
  }

  .editPcWrapeer {
    min-height: calc(100vh - 13vw);
    max-height: calc(100vh - 13vw);
    overflow: auto;
  }

  .ageWrapper {
    max-height: calc(100vh - 18vw);
    min-height: calc(100vh - 18vw);
    overflow: auto;
  }
}