.create-role-tabs .nav-item button {
  font-size: var(--font-size-16);
  font-family: var(--poppins-medium);
  transition: all 0.3s ease;
}

.create-role-tabs .nav-item .active,
.create-role-tabs .nav-item button:hover {
  color: var(--color-primary) !important;
  text-decoration: underline;
  text-underline-offset: 0.2vw;
  text-decoration-thickness: 0.1vw;
}

.create-roles input[type='checkbox'].inputFieldColor {
  background-color: var(--color-white) !important;
  border-color: var(--dark-grey) !important;
}

.create-roles input[type='checkbox']:checked {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.manageRoles.has-scroll,
.createRoleTable.has-scroll {
  padding-right: 1vw;
}

.manageRoles thead,
.createRoleTable thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
}

.createRoleBg {
  background-color: var(--color-white);
  padding: 2vw;
  border-bottom-right-radius: 2vw;
  border-bottom-left-radius: 2vw;
}

@media screen and (min-width:1024px) {
  .manageRoles {
    max-height: 25vw;
    min-height: 25vw;
  }

  .createRoleTable {
    min-height: 19.9vw;
    max-height: 19.9vw;
    overflow: auto;
  }

  .createRoleBg {
    max-height: 60vw;
  }
  .manageContentBg {
    min-height: calc(100vh - 8vw);
  }
}