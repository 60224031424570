.planningEmployeesTitle {
  padding-top: 1.5vw;
  padding-bottom: 1vw;
}

.employeeNameCardHover .employeeName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.employeeNameWithProfile {
  background-color: var(--body-background);
  padding: 0.5vw;
  border-radius: 1vw;
  border: 0.15vw solid var(--body-background) !important;
}

.planningEmployeeHeight.has-scroll {
  padding-right: 1vw;
}

.active-icon>* {
  fill: var(--secondary-color) !important;
}

.nonActiveIcon>* {
  fill: var(--color-primary);
}

.selectedEmployees,
.employeeSchedulingTable {
  background-color: var(--body-background);
  padding: 0.8vw 1.5vw;
  border-radius: 0.4vw;
}

.activeEmployee {
  background-color: var(--secondary-color);
  border: 0.052vw solid var(--secondary-color);
  color: var(--color-white);
}

.inActiveEmployee {
  background-color: var(--color-white);
  border: 0.052vw solid var(--color-white);
  color: var(--color-primary);
}

.planningEmployeeButton {
  border-radius: 0.4vw;
  font-family: var(--poppins-medium);
  font-size: var(--font-size-15);
  padding: 0.2vw;
  word-break: break-all;
}

.selectedEmployees .searchIconWrapper svg {
  margin-top: 1vw;
}

.selectedNumberOfEmployees {
  font-size: var(--font-size-10);
  color: var(--dark-grey);
}

.employeeSchedulingTable td:nth-child(1),
.employeeSchedulingTable td:nth-child(6) {
  width: 8%;
}

.employeeSchedulingTable td:nth-child(2),
.employeeSchedulingTable td:nth-child(3),
.employeeSchedulingTable td:nth-child(4) {
  width: 25%;
}

.employeeSchedulingTable td:nth-child(5) {
  width: 6%;
}

.employeeSchedulingTable td:last-child {
  width: 20%;
}

.employeeSchedulingTable td:nth-child(1) {
  padding-left: 1.5vw;
}

.employeeSchedulingTable td:nth-child(5) {
  padding-right: 1.2vw;
}

.employeeSchedulingTable td {
  background-color: transparent !important;
}

.employeeSchedulingTable tr,
.employeeSchedulingTable td {
  border: 0px;
  padding-top: 0vw;
  padding-bottom: 0vw;
  vertical-align: middle;
}

.employeeSchedulingTable {
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
}

.nextIconWrapper svg:hover,
.backIconWrapper svg:hover {
  outline: 0.052vw solid var(--color-primary);
  outline-offset: 0.1vw;
  border-radius: 50%;
  cursor: pointer;
}

.backIconWrapper svg {
  fill: var(--color-primary);
}

.dayDateWithIcon .prevIcon:hover,
.dayDateWithIcon .nextIcon:hover,
.nextWhiteIconWrapper:hover svg,
.prevWhiteIconWrapper:hover svg {
  outline: 0.052vw solid var(--color-white);
  outline-offset: 0.1vw;
  border-radius: 50%;
  cursor: pointer;
}

.dayDateWithIcon {
  background-color: var(--color-primary);
  border-top-left-radius: 0.5vw;
  border-top-right-radius: 0.5vw;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
}

.plannedLocationHeader {
  padding-top: 1vw;
  padding-bottom: 1vw;
  background-color: var(--color-primary);
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}

.dayDateWithIcon>*,
.dayDateWithIcon .currentDay {
  font-size: var(--font-size-13);
  font-family: var(--poppins-medium);
}

.shiftTimingTable th {
  color: var(--color-primary);
  font-size: var(--font-size-12);
}

.shiftTimingTable .shiftTimingTableData td {
  background-color: var(--body-background);
  vertical-align: middle;
  text-align: center;
}

.shiftTimingTable tr.shiftTimingTableData td:nth-child(1) {
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
  background-color: var(--color-primary) !important;
  color: var(--color-white);
}

.shiftTimingTable tr td:nth-child(1) * {
  font-family: var(--poppins-medium);
}

.shiftTimingTable th:nth-child(1) {
  width: 15%;
  text-align: center;
}

.shiftTimingTable th:not(:nth-child(1)) {
  width: 30%;
  text-align: center;
}

.totalHours {
  background-color: var(--secondary-color);
  width: fit-content;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.shiftTimingTable tr td.emptyRow {
  background-color: var(--color-white) !important;
}

.planningModal .modal-dialog {
  min-width: 70vw !important;
  max-width: 70vw !important;
}

.employeeDetailsCardPlanning {
  background-color: var(--body-background);
  border-radius: 0.5vw;
  padding: 1vw;
}

.planningEmployeeDetails * {
  font-size: var(--font-size-13);
  font-family: var(--poppins-medium);
}

.planningEmployeeDetails {
  margin-top: 0.5vw;
  width: 65%;
}

.planningOverviewModal .selectedEmployees {
  margin-bottom: 1vw;
}

.planningPlannedDate,
.planningPlannedDate span {
  font-family: var(--poppins-medium);
}

.planningDateWrapper {
  border-radius: 0.4vw;
}

.planningTableWrapper td {
  vertical-align: middle;
}

.planningTableWrapper .hourWrapper select,
.planningTableWrapper .minuteWrapper select {
  background-color: var(--body-background);
  border: 0.052vw solid var(--body-background);
}

.planningTableWrapper thead {
  top: 0vw;
}

.dayPlanningOverviewTable {
  height: calc(100vh - 23vw);
  overflow: auto;
}

.dayPlanningOverviewTable thead {
  top: 0vw;
}

.dayPlanningOverviewTable.has-scroll {
  padding-right: 1vw;
}

.PlanningTableContainer {
  max-height: calc(100vh - 13vw);
  overflow: auto;
}

/* Weekly calendar */

.MainLayout-ordinaryLeftPanelBorder,
.planningMonthCalendar .rbc-month-view .rbc-row.rbc-month-header {
  display: none !important;
}

.planningMonthCalendar .rbc-button-link {
  background-color: var(--secondary-color);
  color: var(--color-white);
  border-radius: 50%;
  font-family: var(--poppins-medium);
}

.planningMonthCalendar .rbc-off-range .rbc-button-link {
  background-color: var(--border-color) !important;
}

.planningMonthCalendar .rbc-day-bg {
  background-color: var(--color-white);
}

/* monthy event */
.event-image img {
  border-radius: 50%;
  margin-right: 0.6vw;
}

.event-container {
  position: relative;
}

.event-container:hover .eventTooltip {
  display: block;
  background: var(--body-background) !important;
  color: var(--color-primary);
  width: 12vw;
  white-space: break-spaces;
  padding: 0.5vw;
}

.event-container:hover .eventTooltip * {
  font-family: var(--poppins-medium);
}

.eventTooltip {
  display: none;
  background: var(--color-danger);
  position: absolute;
  top: 50%;
  left: 10%;
  width: max-content;
  z-index: 9999 !important;
}

.rbc-row-content {
  z-index: unset !important;
}

.rbc-month-row {
  overflow: unset !important;
}

.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
  overflow: unset !important;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: var(--color-white) !important;
}

.rbc-show-more {
  width: 5vw !important;
  height: 1vw !important;
  background-color: var(--color-white) !important;
  color: var(--color-primary) !important;
}

/* Weekly planning */
.weekHeaderRow,
.weekData {
  grid-template-columns: repeat(7, 1fr);
  gap: 1vw;
  word-break: break-all;
}

.weekData {
  margin-top: 1vw;
  gap: 0 1vw !important;
}

.weekData .dayDataItem {
  background-color: var(--color-white);
  padding: 1vw;
  border-bottom: 0.052vw solid var(--border-color);
  min-height: 10.5vw;
}

.weekDay {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 0.3vw;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}

.weekDay.currentDay {
  background-color: var(--secondary-color);
}

.plannedTiming,
.plannedOverallTiming {
  font-family: var(--poppins-medium);
  text-align: center;
  padding: 0.1vw;
}

.plannedTiming {
  background-color: var(--body-background);
  color: var(--color-primary);
}

.plannedOverallTiming {
  background-color: var(--secondary-color);
  color: var(--color-white);
}

.weeklyPlannedTimingWrapper {
  margin-top: 0.5vw;
}

.employeeOverviewWrapper {
  height: calc(100vh - 15vw) !important;
}

.employee-selected {
  border: 0.15vw solid var(--color-primary) !important;
}

.employeeNameWithProfile:hover {
  background-color: var(--next-month-bg);
}

.error-border {
  border: 0.104vw solid var(--color-danger);
  border-radius: 0.26vw;
  font-size: var(--font-size-12);
  font-family: var(--poppins-medium);
}

.error-border-card {
  /** planning shift card highlight**/
  border: 0.052vw solid var(--color-danger);
  border-radius: 0.5vw;
  font-size: var(--font-size-12);
  font-family: var(--poppins-medium);
}

.error-message {
  color: var(--color-danger);
  margin-top: 0.5vw;
  font-size: var(--font-size-12);
  font-family: var(--poppins-medium);
}

.plannedColor {
  width: 0.5vw;
  height: 0.5vw;
  background-color: var(--secondary-color);
  border-radius: 50%;
}

.notPlannedColor {
  width: 0.5vw;
  height: 0.5vw;
  background-color: var(--border-color);
  border-radius: 50%;
}


.employeeNameCardHover:hover,
.employeeNameCardActive {
  box-shadow: rgba(0, 0, 0, 0.16) 0vw 0.156vw 0.313vw,
    rgba(0, 0, 0, 0.23) 0vw 0.156vw 0.313vw;
  border-radius: 0.5vw;
  cursor: pointer;
}

td.bodyBg .employeeDailyOverviewTable {
  background-color: var(--color-white) !important;
  border-radius: 0 !important;
}

.locationDashboardTable .spacing-row .bg-transparent {
  background-color: var(--color-white) !important;
}

.planningModal input[type='text'].inputFieldColor {
  min-height: 1.5vw !important;
  height: 1.5vw !important;
}

.planningModal [type='text'].selectPcHeightinput {
  min-height: 1.5vw !important;
}

.planningSearchBtn {
  background-color: var(--color-white) !important;
  border: 0.052vw solid var(--body-background) !important;
}

.planningSearchBtnIcon {
  right: 0.5vw;
}

.selectPcHeight {
  border: 0.052vw solid var(--body-background) !important;
  box-shadow: rgba(133, 113, 185, 0.3) 0px 0.052vw 0.104vw 0px,
    rgba(133, 113, 185, 0.25) 0px 0.052vw 0.156vw 0.052vw !important;
  min-height: 1.4vw !important;
  padding: 0.35vw 0.5vw;
}

.planningTableWrapper th:nth-child(1),
.planningTableWrapper th:nth-child(3),
.planningTableWrapper th:nth-child(8),
.planningTableWrapper th:nth-child(10) {
  width: 5%;
}

.planningTableWrapper th:nth-child(7),
.planningTableWrapper th:nth-child(9) {
  width: 6%;
}

.planningTableWrapper th:nth-child(6),
.planningTableWrapper th:nth-child(11) {
  width: 7%;
}

.planningTableWrapper th:nth-child(2) {
  width: 8%;
}

.planningTableWrapper th:nth-child(12) {
  width: 9%;
}

.planningTableWrapper th:nth-child(4),
.planningTableWrapper th:nth-child(5) {
  width: 12%;
}

.warningAndErrorAlert {
  font-family: var(--poppins-medium) !important;
}


/* Employee scheduling edit */

.employeeSchedulingTableEdit td:nth-child(1),
.employeeSchedulingTableEdit td:nth-child(4) {
  width: 5%;
}

.employeeSchedulingTableEdit td:nth-child(2) {
  width: 25% !important;
}

.employeeSchedulingTableEdit td:nth-child(3) {
  width: 65% !important;
}

.stopIcon svg use {
  fill: var(--color-danger) !important;
}

.forward-step-solid svg use {
  fill: var(--color-danger) !important;
}

.startIcon svg use {
  fill: var(--color-green) !important;
}

.pauseIcon svg use {
  fill: #f7e111 !important;
}

.playPauseIcon svg use {
  fill: #eda302 !important;
}

.startDisabled, .startDisabled:hover svg use,
.playPauseDisabled, .playPauseDisabled svg use,
.pauseDisabled, .pauseDisabled svg use,
.stopDisabled, .stopDisabled svg use {
  cursor: not-allowed !important;
}

.timeRegistrationPerEmployee .form-control:disabled {
  background-color: #8571b94f !important;
  cursor: not-allowed !important;
}

.timeRegistrationDate {
  font-size: var(--font-size-14) !important;
}

.editPlanning .modal-body {
  padding: 1vw !important;
}

.dayPlanningOverviewTable .totalHours {
  min-width: 3vw !important;
  text-align: center;
}

.rbc-month-row .rbc-event {
  margin: 1px;
}

.planningTableWrapper th,
.planningTableWrapper td,
.planningTableWrapper td:not(:last-child) div,
.planningTableWrapper th * {
  color: var(--color-primary);
  font-family: var(--poppins-medium);
  font-weight: normal !important;
}

@media screen and (min-width:1024px) {
  .yourPoolWrapper .empDetails {
    height: 5.2vw;
  }

  .event-container:hover .eventTooltip * {
    font-size: var(--font-size-12);
  }

  .plannedTiming,
  .plannedOverallTiming {
    font-size: var(--font-size-12);
  }

  .planningEmployeeContainer {
    grid-template-columns: repeat(5, 1fr);
    gap: 1vw;
  }

  .timeRegistrationPerEmployee .form-control,
  .warningAndErrorAlert {
    font-size: var(--font-size-13) !important;
  }

  td.bodyBg .employeeDailyOverviewTable {
    height: 1.3vw !important;
    min-height: 1.3vw !important;
  }

  .yourPoolWrapper {
    height: calc(100vh - 16vw);
    overflow: auto;
  }

  .autoCompleteWithSearchWrapper .p-fluid {
    height: 1.6vw;
  }

  .grid-6 {
    grid-template-columns: repeat(6, 1fr);
  }

  .planningModal .react-calendar {
    width: 20.833vw !important;
  }

  .planningEmployeesTitle {
    font-size: var(--font-size-15) !important;
  }

  .textEllipsisName {
    width: 6.35vw;
  }

  .textEllipsisNameWeely {
    width: 6vw;
  }

  .textEllipsisFunName {
    width: 9vw;
  }

  .textEllipsisEmpType {
    width: 3.3vw;
  }

  .planningTableWrapper th,
  .planningTableWrapper td,
  .planningTableWrapper td div {
    font-size: var(--font-size-14) !important;
  }

  .planningTableWrapper * {
    font-size: var(--font-size-14) !important;
  }

  .totalHours {
    font-size: var(--font-size-13);
  }

  .employeeName {
    font-size: var(--font-size-14) !important;
  }

  .employeeNameCardHover .employeeName {
    width: 6vw;
  }

  .plannedLocationHeader * {
    font-size: var(--font-size-17);
  }

  .planningPlannedDate,
  .planningPlannedDate span {
    font-size: var(--font-size-13) !important;
  }

  .dayPlanningOverviewTable {
    height: calc(100vh - 23vw);
  }

  .planningTableWrapper tr td:first-child,
  .timeRegistrationTableWrapper tr th:first-child {
    border-top-left-radius: 0.5vw;
    border-bottom-left-radius: 0.5vw;
  }

  .shiftTimingTable tr td:last-child,
  .planningTableWrapper tr td:last-child,
  .timeRegistrationTableWrapper tr th:last-child {
    border-top-right-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
  }

  .planningEmployeeHeight {
    max-height: calc(100vh - 35vw);
    overflow-y: auto;
  }

  .selectedEmployees .d-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.5vw;
  }

  .employeeWeeklyPlanningTable {
    height: calc(100vh - 30vw);
    overflow: auto;
  }

  .shiftTimingTable tr td:nth-child(1) * {
    font-size: var(--font-size-13);
  }

  .weekDay * {
    font-family: var(--poppins-medium);
    font-size: var(--font-size-15);
  }

  .planningDetails * {
    font-family: var(--poppins-medium);
    font-size: var(--font-size-12);
  }

  .weeklyCalendarImage {
    width: 1.5vw !important;
    height: 1.5vw !important;
    object-fit: cover;
  }

  .planningMonthCalendar .rbc-button-link {
    width: 1.5vw;
    height: 1.5vw;
    font-size: var(--font-size-13);
  }

  .event-image img {
    width: 2vw;
    height: 2vw;
  }
}


@media screen and (min-width:768px) and (max-width:1023px) {

  .weekDay {
    width: 25vw;
  }

  .weeklyCalendarImage {
    width: 5vw !important;
    height: 5vw !important;
    object-fit: cover;
  }

  .planningDetails * {
    font-family: var(--poppins-medium);
    font-size: 1.95vw;
  }

  .event-container:hover .eventTooltip {
    width: 26vw !important;
  }

  .weekDay *,
  .event-container:hover .eventTooltip * {
    font-family: var(--poppins-medium);
    font-size: 1.95vw;
  }

  .planningOverviewHeight {
    max-height: calc(100vh - 60vw);
    overflow: auto;
  }

  .shiftTimingTable tr.shiftTimingTableData td:nth-child(1) {
    padding: 0.5vw 2vw !important;
  }

  .shiftTimingTable tr.shiftTimingTableData td:nth-child(1).dayExpanded {
    background-color: var(--secondary-color) !important;
  }

  .employeeWeeklyPlanningTable {
    height: calc(100vh - 90vw);
    overflow: auto;
  }

  .planningEmployeeHeight {
    max-height: calc(100vh - 71vw);
    overflow-y: auto;
  }

  .planningEmployeeContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 1vw;
  }

  td.bodyBg .employeeDailyOverviewTable,
  .timeRegistrationPerEmployee .form-control,
  .planningModal input[type='text'].inputFieldColor {
    height: 5vw !important;
    min-height: 5vw !important;
  }

  .autoCompleteWithSearchWrapper .p-fluid {
    height: 5vw;
  }

  .dayDateWithIcon>*, .dayDateWithIcon .currentDay {
    font-size: 1.95vw !important;
  }

  .planningModal .react-calendar {
    width: 50vw !important;
  }

  .employeeNameCardHover .employeeName {
    width: 12.5vw;
    margin-top: 1vw !important;
  }

  .plannedLocationHeader *,
  .planningPlannedDate,
  .planningPlannedDate span,
  .shiftTimingTable tr td:nth-child(1) *,
  .warningAndErrorAlert {
    font-size: 1.95vw;
  }

  .dayPlanningOverviewTable {
    height: calc(100vh - 52vw);
  }

  .shiftAddIcon .searchBtn {
    color: var(--color-white) !important;
  }

  .shiftAddIcon .searchBtn:hover,
  .shiftAddIcon .searchBtn:focus {
    color: var(--color-primary) !important;
  }

  .planningDayTooltip .tooltip-inner {
    background-color: var(--color-black) !important;
  }

  .timeRegistrationPerEmployee .form-control:disabled {
    background-size: 1.5vw !important;
  }

  .selectedEmployees .d-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5vw;
  }

  .planningDateWrapper {
    min-height: 3vw !important;
  }

  .overViewButtonStyle span {
    width: 25%;
  }

  .overViewButtonStyleThree span {
    width: 33.33%;
  }

  .weekData .dayDataItem {
    width: 25vw !important;
    position: relative;
    min-height: 32vw !important;
    display: flex;
    flex-direction: column;
  }

  .weekData .dayDataItem>.rowOne {
    flex: 1 1;
  }

  .weekStatusColor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .plannedTiming,
  .plannedOverallTiming {
    font-size: 1.65vw;
  }

  .planningMonthCalendar .rbc-button-link {
    width: 4vw;
    height: 4vw;
    font-size: 1.5vw;
  }

  .event-image img {
    width: 4vw !important;
    height: 4vw !important;
  }
}

.timeRegistrationTable .inputFieldColor:hover.border-danger,
.timeRegistrationTable .inputFieldColor:focus.border-danger {
  box-shadow: none !important;
  border: 0.052vw solid var(--color-danger) !important;
}

.noRecordWeek {
  font-size: var(--font-size-15);
  background-color: var(--color-white);
  padding: 1vw !important;
  height: 100% !important;
  border-bottom: 0.052vw solid var(--border-color) !important;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.enteredPlanningOverview {
  height: calc(100vh - 20vw);
  overflow: auto;
}

.enteredPlanningOverview.has-scroll {
  padding-right: 1.5vw;
}