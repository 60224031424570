.custom-multi-select .dropdown-container {
  min-height: 1.5vw;
  background-color: transparent;
  border: 0;
}

.custom-multi-select .selected-options-container {
  min-height: 1.5vw;
  padding: 1vw 0;
}

.selectedLanguages *,
.custom-multi-select .options label .item-renderer span {
  font-size: var(--font-size-14) !important;
}

.selected-options-container .border {
  border: 0.052vw solid var(--color-primary) !important;
  border-radius: 0.5vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-multi-select .options label .item-renderer {
  align-items: center;
}

.custom-multi-select .rmsc .dropdown-container:focus-within {
  border: 0vw !important;
  box-shadow: none;
}

.custom-multi-select .clear-selected-button svg line {
  color: var(--color-danger);
}

.custom-multi-select .dropdown-heading-dropdown-arrow {
  stroke-width: 0;
  background-image: url("../../../static/images/selectDropdown.svg");
  background-repeat: no-repeat;
  background-size: 1vw;
  background-position: center;
  cursor: pointer;
  margin-left: 0.5vw;
}

.linkLanguagesSelect .search input {
  height: 1vw !important;
}

.linkLanguagesSelect .inputFieldColor {
  padding: 0 !important;
}

@media screen and (min-width:1024px) {
  .applicationName .form-select {
    min-height: 2.1vw !important;
  }

  .functionCategoryField {
    min-height: 2.1vw !important;
  }
}