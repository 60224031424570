.manageShiftTable .shiftNumber {
  font-size: var(--font-size-16);
  color: var(--color-primary);
  font-family: var(--poppins-semibold);
}

.manageShiftTable .totalHours {
  color: var(--color-white);
  min-height: 2vw !important;
  border-radius: 0.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  margin: auto;
}