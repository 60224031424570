/* .salary-benefits-multiselect {
  line-height: 1.563vw !important;
  font-size: 0.938vw;
}

.expand-minimize-div {
  margin-bottom: 1.302vw;
}

.expand-minimize-span,
.expand-minimize-span > svg {
  cursor: pointer;
  vertical-align: text-top;
}

.expand-minimize-span {
  vertical-align: middle;
  height: 1.25vw;
  width: 4%;
  display: inline-block;

}


.expand-minimize-box > span {
  margin-left: 0.781vw;
}

.salay-content-div {
  width: 92%;
  margin-left: 4%;
  border: 0.052vw solid lightgray;
}

.pc-linked-salary-div {
  width: 94%;
  margin-top: 1.042vw;
  margin-left: 3%;
}

.pc-linked-salary-table,
.pc-linked-salary-table > tr,
.pc-linked-salary-table > td {
  border: 0.052vw solid lightgray;
  border-collapse: collapse;
}

.salary-coeff-fields-premies,
.premise-automatisch-div {
  margin: 0.781vw 0;
}

.pc-linked-td {
  padding-left: 1.042vw;
}

.pc-linked-premies-td {
  vertical-align: middle;
  padding-left: 1.042vw;
}

.salary-input-radio-label {
  margin-right: 1.302vw;
}

.salary-coeff-input-value {
  width: 97%;
  margin-left: 0vw;
  border-bottom-color: lightgray !important;
}

.display-visible {
  display: block;
  transition: all 2s linear;
}

.display-hidden {
  display: none;
  opacity: 0;
} */

.expand-minimize-box span{
 font-size: var(--font-size-16);
}
