.loginWrapper {
  padding: 0vw 2vw;
  height: 100vh;
  display: grid;
  align-items: center;
}

.loginLeftSection {
  border-radius: 5.208vw 0vw 0vw 0vw;
  -webkit-border-radius: 5.208vw 0vw 0vw 0vw;
  -moz-border-radius: 5.208vw 0vw 0vw 0vw;
  background-color: var(--color-primary);
  border: 0.052vw solid var(--color-primary);
  padding: 2vw 2vw 0vw 2vw;
}

.loginLeftSectionContent {
  font-size: var(--font-size-24);
  font-family: var(--poppins-semibold) !important;
}

.colorYellow {
  color: var(--color-yellow);
  font-family: var(--poppins-semibold);
  font-size: var(--font-size-24);
}

.loginCard {
  background: var(--color-white) 0% 0% no-repeat padding-box;
  box-shadow: 0.313vw 0.313vw 0.885vw #00000080;
  border-radius: 0vw 2.969vw;
}

.loginImageWrapper img {
  width: 27vw;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.loginMiddleSection {
  margin-top: 2.5vw !important;
  margin-bottom: 1.5vw !important;
  min-height: 79vh;
}

.loginMiddleSection .card {
  border-top-right-radius: 5.208vw !important;
  border-bottom-left-radius: 5.208vw !important;
}

.loginCardPadding {
  padding: 3vw 2.5vw 1vw 2.5vw !important;
  border-radius: 0 !important;
}

.loginCardPaddingSecond {
  padding: 1vw 2.5vw 2vw 2.5vw !important;
}

.loginInText {
  padding-top: 2.5vw;
  padding-bottom: 2.5vw;
  font-family: var(--poppins-medium);
  font-size: var(--font-size-17);
}

.signInBtn {
  background-color: var(--color-primary);
  border: 0.052vw solid var(--color-primary);
  border-radius: 1.4vw !important;
  height: 2.45vw;
  font-family: var(--poppins-semibold);
  color: var(--color-white);
  margin-top: 2.5vw;
  transition: all 0.5s ease;
}

.signInBtn:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
}

.linkColor {
  color: var(--color-primary);
  font-size: var(--font-size-15);
}

.linkColor:hover {
  color: var(--secondary-color);
}

.forgotLinkWrapper {
  padding-bottom: 2.5vw;
}

.newAtAbsoluteYou,
.loginRightSectionTitle {
  font-size: var(--font-size-15);
}

.loginRightSectionTitle {
  margin-top: 0.6vw;
  font-family: var(--poppins-medium);
}

.registerLoginLink {
  font-family: var(--poppins-medium);
  text-decoration: underline;
}

.loginRightSection {
  border-radius: 0vw 0vw 5.208vw 0vw;
  -webkit-border-radius: 0vw 0vw 5.208vw 0vw;
  -moz-border-radius: 0vw 0vw 5.208vw 0vw;
  background-color: var(--color-primary);
  border: 0.052vw solid var(--color-primary);
  padding: 2vw 3vw 0vw 3vw;
  text-transform: uppercase;
  display: grid;
  align-items: center;
  justify-content: center;
}

.loginRightSection [class*='col-'] {
  margin-bottom: 2.5vw !important;
}

.cardEditIcon span {
  padding: 0.3vw;
  border-radius: 0.3vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}



.cardEditIcon span:first-child:hover svg * {
  fill: var(--color-white) !important;
}

.cardEditIcon span:first-child:hover {
  background-color: var(--dark-grey);
}

.employeeRegistrationHeight .delete-icon {
  left: 5.5vw !important;
}

.ssnVerify,
.euCitizenWrapper {
  margin-top: 0.45vw !important;
}


@media screen and (min-width:1024px) {
  .cardEditIcon svg {
    width: 1.2vw !important;
    height: 1.2vw !important;
  }

  .checkBoxContainer {
    min-width: 11vw;
  }
}

@media screen and (min-width:768px) and (max-width:1023px) {
  .checkBoxContainer {
    min-width: 27vw;
  }
}

.forgotPasswordHints {
  min-width: 1.25vw;
  height: 1.25vw;
  margin-right: 1vw;
  background-color: var(--color-primary);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-14);
  border-radius: 50%;
  line-height: 2vw;
  font-family: var(--poppins-medium);
}

.forgotPasswordHintText span {
  font-family: var(--poppins-medium);
  font-size: var(--font-size-14);
}

.loginImageRightSection {
  width: 10vw;
  height: 2vw;
}