.cooperationAgreementTabs {
  position: sticky;
  top: 10.1vw;
  z-index: 990;
  background-color: var(--color-white);
}

.cooperationAgreementTabs .nav-item {
  width: 15% !important;
  word-break: break-all;
  margin: 0 0.5vw;
}

.cooperationAgreementTabs .nav-item .nav-link {
  background-color: var(--tabs-bg-color);
  height: 100%;
  color: var(--dark-grey);
  font-family: var(--poppins-regular);
  font-size: var(--font-size-17);
  border-radius: 0;
  border: 0;
  transition: all 0.5s ease;
  font-weight: normal;
  padding: 1vw !important;
}

.cooperationAgreementTabs .nav-item .nav-link.active,
.cooperationAgreementTabs .nav-item .nav-link:hover {
  background-color: var(--secondary-color);
  color: var(--color-white);
}

.cooperationAgreementTabs .nav-item:first-child {
  margin-left: 0 !important;
}

.cooperationAgreementTabs .nav-item:last-child {
  margin-right: 0 !important;
}

.agreementStartDate .calender-icon {
  top: 1.7vw !important;
}

.agreementStartDate .react-datepicker-wrapper {
  width: 100%;
}

.companyLanguage input[type='radio'] {
  width: 1vw !important;
  height: 1vw !important;
  margin-top: 0 !important;
}

.companyLanguage .radioBtn {
  display: inline-flex !important;
  align-items: center;
}

.cooperationAgreementWrapper .accordion .accordion-body {
  border: 0.052vw solid var(--color-primary) !important;
  border-bottom-left-radius: 1.3vw;
  border-bottom-right-radius: 1.3vw;
}

.cooperationAgreementWrapper .accordion-button.collapsed {
  border-bottom-right-radius: 1.3vw !important;
  border-bottom-left-radius: 1.3vw !important;
}

.cooperationAgreementWrapper .accordion {
  width: 100% !important;
  margin-right: 1vw;
}

.vatRate .fieldSuffix {
  right: 0.09vw !important;
}

.absoluteAgentTable thead th,
.absoluteAgentTable thead th * {
  font-size: var(--font-size-16);
  font-family: var(--poppins-medium);
}

.createTabs .nav-item button {
  font-size: var(--font-size-16);
  font-family: var(--poppins-medium);
  transition: all 0.3s ease;
}


.createTabs .nav-item.active button,
.createTabs .nav-item button:hover {
  color: var(--color-primary);
  text-decoration: underline;
  text-underline-offset: 0.25vw;
  text-decoration-thickness: 0.1vw;
}

.agreementStartDate .input-group-text {
  padding: 0.25vw !important;
}

.cooperationAgreementWrapper .cooperationAgreementTabs {
  padding: 0vw 2vw 1vw !important;
}

.cooperationAgreementWrapper .tab-pane {
  padding: 0vw 2vw !important;
  border-bottom-left-radius: 2vw !important;
  border-bottom-right-radius: 2vw !important;
}

.cooperationAgreementBackLink {
  height: 5vw;
}

