.calendarWrapper .react-calendar .react-calendar__navigation button:first-child,
.calendarWrapper .react-calendar .react-calendar__navigation button:last-child {
  display: none;
}

.calendarWrapper .react-calendar__navigation,
.react-calendar__navigation button:disabled {
  margin-bottom: 0px;
  background-color: var(--color-primary) !important;
  height: 1.5vw !important;
  display: flex;
  align-items: center;
}

.react-calendar__navigation button:disabled {
  justify-content: center;
}

.calendarWrapper .react-calendar__navigation__label span {
  color: var(--color-white);
  font-size: var(--font-size-15);
  font-family: var(--poppins-semibold);
}

.calendarWrapper .react-calendar__navigation__arrow {
  width: 0.8vw !important;
  min-width: 0.8vw !important;
  height: 0.8vw !important;
  border-radius: 50%;
  background-color: var(--color-white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary) !important;
  font-size: 1.2vw !important;
  font-family: var(--poppins-medium);
}

.calendarWrapper .react-calendar__navigation__prev-button {
  margin-left: 1vw !important;
}

.calendarWrapper .react-calendar__navigation__next-button {
  margin-right: 1vw !important;
}

.calendarWrapper .react-calendar__navigation .react-calendar__navigation__label:enabled:hover,
.calendarWrapper .react-calendar__navigation .react-calendar__navigation__label:enabled:focus {
  background-color: var(--color-primary) !important;
}

.calendarWrapper .react-calendar__navigation__arrow:hover {
  outline: 0.052vw solid var(--color-white);
  outline-offset: 0.156vw;
}

.calendarWrapper .react-calendar__month-view {
  background-color: var(--body-background);
}

.calendarWrapper .react-calendar {
  border: 0px !important;
  border-radius: 5% !important;
}

.calendarWrapper abbr[title] {
  text-decoration: none !important;
  pointer-events: none;
  font-size: var(--font-size-11);
  color: var(--color-primary);
  font-family: var(--poppins-regular);
  text-transform: capitalize;
}

.calendarWrapper .react-calendar__month-view__weekNumbers {
  color: var(--border-color);
  position: relative;
  top: 0vw;
}

.calendarWrapper .react-calendar__month-view__weekNumbers span,
.calendarDateFont abbr,
.react-datepicker__day {
  font-size: var(--font-size-12) !important;
}

.highligh .calendarWrapper .react-calendar__month-view__weekNumbers span {
  min-height: 1.17vw !important;
}

.calendarWrapper .react-calendar__tile {
  border-top: 0.156vw solid var(--color-white);
  border-left: 0.156vw solid var(--color-white);
}

.calendarWrapper .react-calendar__tile--actives,
.calendarWrapper .react-calendar__month-view__days .react-calendar__tile:hover,
.react-calendar__year-view__months__month:hover,
.react-calendar__decade-view__years__year:hover,
.react-calendar__century-view__decades__decade:hover,
.react-calender_week_navigation,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day:hover {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 0.8vw 1vw !important;
}

.react-calendar__month-view__days__day--weekend,
.react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--neighboringMonth),
.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year,
.react-calendar__century-view__decades__decade {
  color: var(--color-primary);
  cursor: not-allowed;
}

.calendarWrapper .react-calendar__tile>span,
.calendarWrapper .react-calendar__tile>abbr,
.react-calendar__year-view__months__month>span,
.react-calendar__decade-view__years__year,
.react-calendar__century-view__decades__decade {
  font-family: var(--poppins-medium) !important;
  font-size: var(--font-size-12);
}


.calendarWrapper .react-calendar__month-view__days__day--neighboringMonth,
.react-datepicker__day--outside-month {
  background-color: var(--next-month-bg);
}

.react-calendar .react-calendar__tile--now,
.react-calendar .react-datepicker__day--today {
  background-color: var(--secondary-color) !important;
  color: var(--color-white) !important;
}

.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year,
.react-calendar__century-view__decades__decade {
  font-size: var(--font-size-12) !important;
}


.calendarWrapper .react-calendar__navigation {
  border-top-left-radius: 0.5vw;
  border-top-right-radius: 0.5vw;
}

/* date picker */
.react-datepicker__current-month,
.react-datepicker__day-name {
  color: var(--color-primary) !important;
  font-size: var(--font-size-14) !important;
  font-family: var(--poppins-medium);
}

.react-datepicker__current-month {
  margin-bottom: 0.6vw !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: var(--color-primary) !important;
}

.react-datepicker__navigation-icon {
  top: 4px !important;
}


.react-datepicker__day:not(.react-datepicker__day--keyboard-selected):not(.react-datepicker__day--outside-month) {
  color: var(--color-primary);
  font-family: var(--poppins-medium) !important;
}

.react-datepicker__day {
  border-radius: 0px !important;
}

.react-datepicker__day--outside-month {
  color: var(--dark-grey) !important;
  font-family: var(--poppins-medium) !important;
}

.react-calendar {
  width: fit-content !important;
}

.date-disabled,
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  background-color: #f0f0f0;
  color: #ababab !important;
  cursor: not-allowed !important;
}

.react-calendar .date-selected,
.react-calendar .react-datepicker__day--selected,
.react-datepicker__day--selected {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.date-deselected {
  background-color: var(--body-background) !important;
  color: var(--color-primary) !important;
}

.current-date {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.rbc-current .rbc-button-link {
  background-color: var(--color-primary) !important;
}

@media screen and (min-width:768px) and (max-width:1023px) {

  .calendarWrapper .react-calendar__tile>span, .calendarWrapper .react-calendar__tile>abbr, .react-calendar__year-view__months__month>span, .react-calendar__decade-view__years__year, .react-calendar__century-view__decades__decade,
  .calendarWrapper abbr[title],
  .calendarWrapper .react-calendar__navigation__label span {
    font-size: 1.6vw !important;
  }

  .calendarWrapper .react-calendar__month-view__weekNumbers .react-calendar__tile,
  .calendarWrapper .react-calendar__tile {
    padding: 1vw !important;
  }

  .calendarWrapper .react-calendar__navigation, .react-calendar__navigation button:disabled {
    height: 4vw !important;
  }

  .calendarWrapper .react-calendar__navigation__arrow {
    width: 2.5vw !important;
    height: 2.5vw !important;
    font-size: 3vw !important;
  }
}

@media screen and (min-width:1024px) {
  .adminDate {
    font-size: 0.8vw !important;
    font-family: var(--poppins-medium);
  }

  .calendarWrapper .react-calendar__month-view__weekNumbers .react-calendar__tile {
    padding: 0.525vw !important;
  }

  .calendarWrapper .react-calendar__tile {
    padding: 0.495vw !important;
  }

  .calender-icon.disabled-class {
    right: 1vw !important;
  }
}

.myProfilePageWrapper .calendarSectionWrapper .calender-icon .input-group-text {
  padding: 0.25vw;
}

.myProfilePageWrapper .calendarSectionWrapper .calender-icon {
  top: 50%;
  transform: translate(0px, -50%);
}