.inVoiceHeader {
  font-size: var(--font-size-24) !important;
  font-family: var(--poppins-semibold) !important;
}

.invoiceData {
  color: var(--dark-grey);
  font-family: var(--poppins-semibold);
  font-size: var(--font-size-14);
  line-height: 1.4vw;
}

.invoiceSettingsLink {
  color: var(--color-primary);
  font-family: var(--poppins-semibold);
  font-size: var(--font-size-14);
  line-height: 1.4vw;
}

.invoiceAmount {
  font-size: var(--font-size-17);
  font-family: var(--poppins-semibold);
}

.tableBg {
  background-color: var(--body-background);
  --bs-table-bg: var(--body-background);
}

.totalInvoiceAmount {
  font-size: var(--font-size-24);
  font-family: var(--poppins-semibold);
}

.payHere {
  font-size: var(--font-size-14);
  color: var(--secondary-color);
  font-family: var(--poppins-semibold);
}

.invoiceLeftWrapper.has-scroll {
  padding-right: 1vw;
}


.invoiceLeftWrapper .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg) !important;
}

.invoiceLeftWrapper .accordion-button::after {
  margin-right: auto !important;
  margin-left: 1vw;
  background-image: url(../../../static/images/selectDropdown.svg);
  background-size: 0.7vw;
  width: 1vw;
  transform: rotate(0deg) !important;
  height: 0.7vw;
}

.invoiceLeftWrapper .accordion-body {
  padding: 0px !important;
}

.close-btn {
  z-index: 1;
  top: 14%;
  right: 5.75%;
}

.rpv-core__inner-pages {
  overflow: hidden !important;
}

.relative {
  position: relative;
}

.pdf-controls {
  bottom: 5rem;
  left: 38.5%;
  transform: translateX(120%);
  background-color: var(--color-primary);
}

.pendingInvoiceCircle {
  width: 0.7vw;
  height: 0.7vw;
  background-color: var(--body-background) !important;
  position: absolute;
  left: -2.3vw;
  border-radius: 50%;
  top: 1vw;
}

.syncSearchBtn {
  min-width: auto !important;
}


@media screen and (min-width:768px) and (max-width:1023px) {
  .inVoiceHeader {
    font-size: 3vw !important;
  }

  .invoiceLeftWrapper .accordion-button::after {
    background-size: 2vw !important;
    width: 2vw !important;
    height: 2vw !important;
  }

  .invoiceData {
    font-size: 1.95vw !important;
    line-height: 3vw !important;
  }

  .invoiceAmount {
    font-size: 1.95vw !important;
  }

  .totalInvoiceAmount {
    font-size: 2.5vw !important;
  }
  .syncSearchBtn {
    padding: 1vw 2vw !important;
  }
}

@media screen and (min-width:1024px) {
  .invoicesEnquiry {
    font-size: var(--font-size-20) !important;
    font-family: var(--poppins-semibold);
    color: var(--color-primary);
  }

  .inclBtwFont {
    font-size: 0.9vw;
  }

  .inClBtwFontSize {
    font-size: 0.7vw;
  }

  .invoiceLeftWrapper {
    height: calc(100vh - 12vw);
    overflow: auto;
  }

  .grid-invoice-2 {
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1vw;
  }

  .invoiceLeftWrapper .accordion-button::after {
    background-size: 0.7vw;
    width: 4vw;
    height: 0.7vw;
  }
  .syncSearchBtn {
    padding: 0.5vw 1vw !important;
  }
}

@media screen and (min-width:0px) and (max-width:767px) {
  .inVoiceHeader {
    font-size: 4.5vw !important;
  }

  .invoiceData {
    font-size: 3.25vw !important;
    line-height: 5vw !important;
  }

  .invoiceAmount {
    font-size: 3.5vw !important;
  }

  .totalInvoiceAmount {
    font-size: 3.25vw !important;
  }

  .invoiceLeftWrapper .accordion-button::after {
    background-size: 3.7vw;
    width: 4vw;
    height: 3.7vw;
  }
  .syncSearchBtn {
    padding: 1.5vw 3.5vw !important;
  }
}