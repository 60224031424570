.contractTable .spaced-row td {
  font-size: var(--font-size-13);
  font-family: var(--poppins-medium);
  color: var(--color-primary);
  background-color: var(--body-background);
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.contractTable .spaced-row td:first-child {
  border-top-left-radius: 0.8vw;
  border-bottom-left-radius: 0.8vw;
}

.contractTable .spaced-row td:last-child {
  border-top-right-radius: 0.8vw;
  border-bottom-right-radius: 0.8vw;
}

.ContractsLeftWrapper.has-scroll {
  padding-right: 1vw !important;
}

@media screen and (min-width:1024px) {
  .ContractsLeftWrapper {
    height: calc(100vh - 17vw);
    overflow: auto;
  }
}

@media screen and (min-width:768px) and (max-width:1023px) {
  .showPadinInvoicesText {
    font-size: 2.5vw !important;
  }
}

@media screen and (min-width:0px) and (max-width:767px) {
  .showPadinInvoicesText {
    font-size: 3.5vw !important;
  }
}